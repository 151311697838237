@import "/src/styles/custom-scss/mixins/media-queries.scss";

body {
  cx-storefront {
    &.ProductDetailsPageTemplate {
      app-custom-pdp-rebate {
        .cx-rebate-pdp {
          @include mobile {
            margin-top: 1.5rem;
            .pdp-rebate-container {
              margin-bottom: 0;
            }
            .pdp-rebate-container__open {
              flex-direction: column;
              justify-content: center;
              align-items: unset;
            }
          }
        }
      }
    }
  }
  cx-page-layout {
    &.ProductDetailsPageTemplate {
      padding: 3.9375rem 4.5rem 0;

      @include custom-small-desktop {
        padding: 2.5rem 2rem 0;
      }

      @include mobile {
        padding: 0rem 1rem;
        padding-top: 0 !important;
      }

      cx-page-slot {
        &.Summary {
          @include desktop {
            grid-column-gap: 6rem;
            grid-template-columns: 1.5fr 1.2fr 1fr;
            grid-template-rows: minmax(12.5rem, auto) 1fr;
            grid-auto-rows: auto;
            height: auto;
            margin-bottom: 1rem;
            padding: 0;
            justify-content: space-between;

            @include custom-small-desktop {
              grid-template-columns: auto;
            }

            > app-custom-product-images {
              height: 100%;
              width: 100%;
              min-height: 30rem;
              display: flex;
              justify-content: center;

              @include custom-small-desktop {
                max-width: 30.75rem;
                height: auto;
                min-height: auto;
                max-height: 24rem;
              }
            }

            > app-custom-product-intro {
              grid-column: 2;
              display: block;
              height: fit-content;
              min-height: 12.5rem;
              height: auto;
              max-width: 30.1875rem;

              @include custom-small-desktop {
                max-width: 26.375rem;
                width: 100%;
                height: auto;
              }
            }

            > app-custom-stock-notification {
              order: 6;
              grid-column: 2;
            }

            > app-custom-social-share {
              order: 7;
              grid-column: 2;
            }

            > custom-add-to-cart {
              grid-column: 3;

              @include custom-small-desktop {
                max-width: 21.25rem;
                width: 21.25rem;
              }

              @media screen and (min-width: 118.75rem) {
                width: 26.9375rem;
                justify-self: end;
              }
            }
            > app-custom-promeclub-product-points {
              display: none;
            }

            > app-custom-product-summary {
              display: none;
            }

            > yrs-custom-product-variants-container {
              display: none;
            }
          }

          @include custom-small-desktop {
            grid-column-gap: 1.5rem;
            display: grid;
          }

          @include notebook-desktop {
            justify-content: space-between;
          }

          @include extra-large-desktop {
            justify-content: flex-end;
          }

          @media screen and (min-width: 100rem) and (max-width: 112.5rem) {
            grid-column-gap: 4rem;
          }

          @include mobile {
            position: relative;
            padding-left: 0rem !important;
            padding-right: 0rem !important;
            margin-bottom: 0rem;

            > app-custom-product-images,
            cx-product-images {
              order: 2;
              width: 100%;
              margin-top: 1.5625rem;
            }

            > app-custom-product-intro {
              order: 1;

              > yrs-custom-product-variants-container {
                display: none;
              }
            }

            > app-custom-product-summary {
              order: 5;
              margin-top: 1.5rem;
            }

            > yrs-custom-product-variants-container {
              order: 3;
              margin: 0;
            }

            > app-custom-product-add-to-cart,
            cx-add-to-cart,
            custom-add-to-cart {
              order: 4;
            }
            custom-add-to-cart {
              display: block;
              max-width: 100%;
              overflow: hidden;
            }
            > app-custom-stock-notification {
              order: 6;
            }

            > app-custom-availability {
              order: 7;
            }

            > app-custom-social-share {
              order: 8;
            }
          }

          > cx-add-to-wishlist {
            display: none;
          }
        }

        &.UpSelling {
          cx-product-references {
            padding-bottom: 0;
          }
        }

        &.Tabs {
          @include mobile {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        }

        max-width: var(--cx-page-width-max) !important;
        margin: auto;
      }

      @include mobile {
        padding-top: 1.5rem;
      }
    }
  }

  &.modal-open {
    &.cart-pop-up-wrapper {
      .modal-backdrop {
        z-index: unset !important;
        top: unset;
        left: 0;
        background-color: transparent;

        @include mobile-sm {
          background-color: var(--cx-color-white);
          opacity: 1;
        }
      }

      .modal {
        width: unset;
        height: unset;

        position: absolute;
        left: unset;
        top: 3.125rem;
        right: -0.25rem;
        overflow: unset !important;

        margin: 0 1.25rem;

        @include desktop {
          top: 3.375rem;
          right: -0.9375rem;
          margin: 0;
        }

        @include notebook-desktop {
          top: 4.9375rem;
          right: -0.25rem;
        }

        @include large-desktop {
          right: -8.625rem;
        }
      }

      .modal-dialog {
        min-width: 28.125rem !important;
        max-width: 28.125rem !important;

        margin: 0;

        > .modal-content {
          background-color: transparent;
        }

        @include mobile {
          min-width: 20.1875rem !important;
          max-width: 20.1875rem !important;
        }
      }
    }
  }
}

cx-storefront {
  &.ProductDetailsPageTemplate {
    .BottomHeaderSlot app-custom-breadcrumb {
      width: 100%;
    }

    app-custom-floating-add-to-cart {
      @include mobile {
        &.no-points {
          .floating-add-to-cart.floating-add-to-cart--active {
            min-height: 7.35rem;
          }
        }
      }
    }
    cx-page-layout {
      &.footer {
        cx-page-slot {
          &.Footer {
            @include mobile {
              padding-bottom: 9.75rem;
              &.not-logged {
                padding-bottom: 9.75rem;
              }
              &.no-points {
                padding-bottom: 7.35rem;
              }
            }

            &.promeclub {
              @include mobile {
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
