@import "src/styles/custom-scss/mixins/media-queries.scss";

header {
  .header {
    cx-page-slot.SearchBox {
      z-index: 2;
      @media screen and (min-width: 118.75rem) {
        max-width: 69.875rem !important;
        width: 100% !important;
      }

      @include desktop {
        width: 100%;
        max-width: 58%;
        display: flex;
        justify-content: center;
        margin: 1.25rem auto;

        cx-searchbox {
          max-width: 70.3125rem;

          @include custom-small-desktop {
            max-width: 39.125rem;
            max-height: 2.5rem;
          }

          @include large-desktop {
            max-width: 69.875rem;
            max-height: 2.5rem;
          }

          label {
            background: var(--cx-color-white);
            color: var(--cx-color-secondary);
            min-width: unset;
            max-width: 70.3125rem;
            width: 100%;
            border-radius: 0.3125rem;
            max-height: 3.75rem;
            padding-top: 0;
            padding-inline-end: 0;
            padding-bottom: 0;
            align-items: center;

            @include custom-small-desktop {
              max-height: 2.5rem;
            }
            @include large-desktop {
              max-height: 2.5rem;
            }

            &.dirty div.search-icon,
            div.search-icon {
              display: unset;
              padding: 0;
            }

            input {
              height: 3.125rem;
              color: var(--cx-color-primary);

              @include custom-small-desktop {
                font-size: 0.875rem;
                line-height: 1.1875rem;
                max-height: 2.5rem;
              }

              @include large-desktop {
                max-height: 2.5rem;
                font-size: 14px;
              }

              @include mobile {
                color: #000000;
                font-weight: bold;
              }

              &::placeholder {
                color: var(--promesa-color-neutrals-04);
              }
            }
          }

          li {
            &:last-child {
              app-custom-searchbox-product-item {
                @include custom-small-desktop {
                  margin-bottom: 1.5rem;
                }
              }
            }
          }
        }
      }

      @include large-desktop {
        margin-top: 12px;
        margin-bottom: 8px;
      }

      @include mobile {
        margin-bottom: 0;
      }
    }
  }
}

body.searchbox-is-active,
body {
  header {
    .header {
      cx-page-slot.SearchBox {
        @include mobile {
          width: 100%;
          padding-bottom: 0rem;
          z-index: 0;
          margin-top: 0.5rem;

          cx-searchbox {
            height: 3.125rem;

            label {
              background-color: var(--cx-color-white);
              color: var(--cx-color-secondary);
              padding-top: 0;
              padding-bottom: 0;
              padding-inline-end: 0.625rem;
              padding-inline-start: 0;
              border-radius: 0.3125rem;

              input {
                position: unset;
                background-color: var(--cx-color-white);
                height: 3.125rem;
                border-bottom: none;
                color: var(--cx-color-secondary);
                max-width: 100%;
                width: 100%;
                padding-inline-start: 0;
                font-weight: bold;

                &::placeholder {
                  font-size: 1rem;
                  letter-spacing: -0.005rem;
                  padding-inline-start: 0;
                  font-weight: 400;
                }
              }

              @include mobile {
                z-index: 11;
                position: relative;
              }
            }

            .results {
              position: absolute;
              left: 0;
              width: 100%;

              @include desktop {
                top: 10.3125rem;
              }

              @include custom-small-desktop {
                top: 10.3125rem;
                top: 2.75rem;
              }

              @media screen and (min-width: 48rem) and (max-width: 61.9988rem) {
                top: 5rem;
              }

              @include mobile {
                // display: block;
                top: 9.3125rem;
                min-height: calc(100vh - 150px);
                overflow: scroll;
                height: -webkit-fill-available;

                &.results-scroll {
                  top: 3.5rem;
                }
              }

              .products {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 0.5rem;

                @media screen and (max-width: 20rem) {
                  grid-template-columns: 1fr;
                  column-gap: 0;
                  row-gap: 0.5rem;
                }
              }
            }
          }
        }

        @include custom-small-desktop {
          max-width: 39.125rem;
          margin: 0.75rem auto 0.5rem;
        }

        @media screen and (min-width: 48rem) and (max-width: 62rem) {
          cx-searchbox {
            label {
              width: 100%;
              min-width: unset;
              max-width: 100%;

              button.search {
                display: unset;
              }
            }
          }
        }

        cx-searchbox {
          label {
            padding-inline-start: 0.125rem;
          }
        }
      }
    }
  }
}

body.searchbox-is-active.has-searchbox-results {
  @include mobile {
    overflow: hidden;
  }

  cx-page-slot.SearchBox {
    cx-searchbox {
      label.searchbox {
        @include desktop {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .results {
        display: block;
      }
    }
  }
}
