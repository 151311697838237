cx-bulk-pricing-table table {
  text-align: center;
}

@media (min-width: 992px) {
  cx-product-variants-container .variant-section {
    padding-top: 20px;
    padding-inline-end: 20px;
    padding-bottom: 0px;
    padding-inline-start: 20px;
  }
}
cx-product-variants-container .variant-selector a.size-guide {
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
}
cx-product-variants-container .variant-selector .style-name {
  text-transform: capitalize;
}
cx-product-variants-container .variant-list {
  padding: 0;
}
cx-product-variants-container .variant-list li {
  display: inline-block;
}
cx-product-variants-container .variant-list li .variant-button {
  margin: 0;
  padding: 0;
  appearance: none;
  outline: 0;
  border: 0;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  background-color: transparent;
}
cx-product-variants-container .variant-list li .variant-button:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
cx-product-variants-container .variant-list li .variant-button img {
  margin: 3px;
  border: 1px solid #ddd;
}
cx-product-variants-container .variant-list li.selected-variant button img {
  border: 2px solid var(--cx-color-primary);
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog, cx-product-image-zoom-dialog .cx-image-zoom-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog, .modal.fade cx-product-image-zoom-dialog .cx-image-zoom-dialog, cx-product-image-zoom-dialog .modal.fade .cx-image-zoom-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog, .modal.fade cx-product-image-zoom-dialog .cx-image-zoom-dialog, cx-product-image-zoom-dialog .modal.fade .cx-image-zoom-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog, .modal.show cx-product-image-zoom-dialog .cx-image-zoom-dialog, cx-product-image-zoom-dialog .modal.show .cx-image-zoom-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog, .modal.modal-static cx-product-image-zoom-dialog .cx-image-zoom-dialog, cx-product-image-zoom-dialog .modal.modal-static .cx-image-zoom-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content, .modal-dialog-scrollable cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content, cx-product-image-zoom-dialog .cx-image-zoom-dialog .modal-dialog-scrollable .cx-dialog-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content .cx-dialog-header, cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content .modal-dialog-scrollable .cx-dialog-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body, .modal-dialog-scrollable cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content .cx-dialog-body, cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content .modal-dialog-scrollable .cx-dialog-body {
  overflow-y: auto;
}

.modal-dialog-centered, cx-product-image-zoom-dialog .cx-image-zoom-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before, cx-product-image-zoom-dialog .cx-image-zoom-dialog::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable, cx-product-image-zoom-dialog .modal-dialog-scrollable.cx-image-zoom-dialog {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content, .modal-dialog-centered.modal-dialog-scrollable cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content, cx-product-image-zoom-dialog .cx-image-zoom-dialog .modal-dialog-centered.modal-dialog-scrollable .cx-dialog-content, cx-product-image-zoom-dialog .modal-dialog-scrollable.cx-image-zoom-dialog .modal-content, cx-product-image-zoom-dialog .modal-dialog-scrollable.cx-image-zoom-dialog .cx-dialog-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before, cx-product-image-zoom-dialog .modal-dialog-scrollable.cx-image-zoom-dialog::before {
  content: none;
}

.modal-content, cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header, cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content .cx-dialog-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close, cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content .cx-dialog-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body, cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content .cx-dialog-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog, cx-product-image-zoom-dialog .cx-image-zoom-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content, .modal-dialog-scrollable cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content, cx-product-image-zoom-dialog .cx-image-zoom-dialog .modal-dialog-scrollable .cx-dialog-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered, cx-product-image-zoom-dialog .cx-image-zoom-dialog {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before, cx-product-image-zoom-dialog .cx-image-zoom-dialog::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg, cx-product-image-zoom-dialog .cx-image-zoom-dialog,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal-header, cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content .cx-dialog-header {
  padding: 1rem 1rem;
}
.modal-body, cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content .cx-dialog-body {
  padding: 27px 30px;
}
.modal-content, cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content {
  border-radius: 0;
  border: none;
}
.modal .close {
  font-size: 38px;
  font-weight: 100;
  bottom: 5px;
  position: relative;
  margin-inline-start: 0;
  margin-inline-end: 0;
  align-self: flex-end;
}
.modal[aria-hidden=false] {
  display: block;
}

.modal-xl,
.modal-lg,
cx-product-image-zoom-dialog .cx-image-zoom-dialog {
  max-width: 768px !important;
  min-width: 768px !important;
}

@media (min-width: 768px) {
  .modal-lg, cx-product-image-zoom-dialog .cx-image-zoom-dialog {
    max-width: 768px !important;
    min-width: 768px !important;
  }
}
@media (max-width: 767.98px) {
  .modal-lg, cx-product-image-zoom-dialog .cx-image-zoom-dialog {
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .modal-dialog, cx-product-image-zoom-dialog .cx-image-zoom-dialog {
    margin: 0 !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .modal-dialog-centered, cx-product-image-zoom-dialog .cx-image-zoom-dialog {
    margin: 0 !important;
    height: 100%;
    align-items: unset !important;
    overflow-y: auto;
  }

  .modal-dialog, cx-product-image-zoom-dialog .cx-image-zoom-dialog {
    overflow-y: initial;
  }
}
cx-product-image-zoom-dialog {
  color: var(--cx-color-text);
  background-color: rgba(0, 0, 0, 0.5);
}
@media (min-width: 768px) {
  cx-product-image-zoom-dialog .cx-image-zoom-dialog {
    max-width: 90vw !important;
    min-width: 90vw !important;
    height: 90vh;
  }
}
@media only screen and (max-width: 900px) and (orientation: landscape) {
  cx-product-image-zoom-dialog .cx-image-zoom-dialog {
    max-width: 100% !important;
    min-width: 100% !important;
    height: 100%;
    margin: 0;
  }
}
cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content {
  height: 100%;
}
cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content .cx-dialog-header {
  padding: 1.5rem 1.75rem 0.85rem;
  border-bottom: none;
  max-height: 76px;
}
@media only screen and (max-width: 900px) and (orientation: landscape) {
  cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content .cx-dialog-header {
    padding: 1rem;
  }
}
cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content .cx-dialog-body {
  background-color: var(--cx-color-inverse);
  height: calc(100% - 76px);
}
@media only screen and (max-width: 900px) and (orientation: landscape) {
  cx-product-image-zoom-dialog .cx-image-zoom-dialog .cx-dialog-content .cx-dialog-body {
    padding: 0;
  }
}

cx-product-image-zoom-trigger {
  display: flex;
  justify-content: flex-end;
}
cx-product-image-zoom-trigger .cx-action-link {
  padding: 0;
  height: 24px;
  margin: 0.5em auto;
}
cx-product-image-zoom-trigger .cx-action-link cx-icon {
  padding: 0 0 0 0.4em;
}

cx-product-image-zoom-view {
  display: flex;
  flex-direction: column;
  height: 100%;
}
cx-product-image-zoom-view .cx-main-image-group {
  display: flex;
  justify-content: center;
  padding-bottom: 1em;
}
@media only screen and (max-width: 900px) and (orientation: landscape) {
  cx-product-image-zoom-view .cx-main-image-group {
    height: 100%;
  }
}
cx-product-image-zoom-view .cx-main-image-group .cx-navigate-image {
  display: flex;
}
cx-product-image-zoom-view .cx-main-image-group .cx-navigate-image button {
  align-self: center;
  font-size: 2rem;
}
cx-product-image-zoom-view .cx-main-image-group .cx-default-image-zoom {
  display: flex;
  justify-content: center;
  max-height: calc(90vh - 200px);
}
@media (min-width: 992px) {
  cx-product-image-zoom-view .cx-main-image-group .cx-default-image-zoom {
    height: calc(90vh - 200px);
  }
}
@media only screen and (max-width: 900px) and (orientation: landscape) {
  cx-product-image-zoom-view .cx-main-image-group .cx-default-image-zoom {
    height: 100%;
    max-height: 100%;
  }
}
cx-product-image-zoom-view .cx-main-image-group .cx-default-image-zoom:hover {
  cursor: zoom-in;
}
cx-product-image-zoom-view .cx-zoom-container {
  width: 100%;
  height: calc(90vh - 200px);
  overflow: hidden;
}
@media only screen and (max-width: 900px) and (orientation: landscape) {
  cx-product-image-zoom-view .cx-zoom-container {
    height: 100%;
  }
}
cx-product-image-zoom-view .cx-zoom-container .cx-image-zoomed {
  height: 100%;
  width: 100%;
  padding: 1em;
  -webkit-touch-callout: none;
}
cx-product-image-zoom-view .cx-zoom-container .cx-image-zoomed:hover {
  cursor: zoom-out;
}
cx-product-image-zoom-view .cx-zoom-container .cx-image-zoomed img {
  transform: scale(2);
  position: relative;
  transition: background-position 0.25s;
  object-fit: cover;
}
cx-product-image-zoom-view cx-product-image-zoom-thumbnails .thumbs {
  flex: auto;
}
cx-product-image-zoom-view cx-product-image-zoom-thumbnails .thumbs cx-media {
  width: 70px;
  height: 70px;
}
@media only screen and (max-width: 900px) and (orientation: landscape) {
  cx-product-image-zoom-view cx-product-image-zoom-thumbnails .thumbs {
    display: none;
  }
}

cx-product-image-zoom-thumbnails .carousel-panel {
  align-self: center;
}
cx-product-image-zoom-thumbnails .carousel-panel .slide.active {
  display: flex;
  justify-content: center;
}