@import "src/styles/custom-scss/mixins/media-queries.scss";
@import "/src/styles/custom-scss/placeholders/header-small-desktop.scss";

.header {
  cx-page-slot.MiniCart {
    @include desktop {
      z-index: 10;
    }
    app-custom-mini-cart {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include mobile {
        margin-right: 0;
        column-gap: 0.3125rem;
        a {
          display: flex;
        }
      }
      @include desktop {
        a {
          display: flex;
        }
      }
    }

    @extend %margin-top-header-slots-small-dekstop;
  }
}
