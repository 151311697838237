@import "src/styles/custom-scss/mixins/media-queries.scss";

cx-storefront {
  &.AccountPageTemplate {
    app-custom-breadcrumb {
      width: 100%;

      .custom-breadcrumb {
        padding-bottom: 1.5rem;

        @include custom-small-desktop {
          padding-bottom: 0;
        }
      }
    }

    cx-page-layout {
      &.AccountPageTemplate {
        cx-page-slot {
          &.BodyContent {
            &.wishlist {
              margin: 0;
              max-width: 110.75rem;
            }
          }
        }
      }
    }
  }
}
