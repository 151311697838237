@import "src/styles/custom-scss/mixins/media-queries.scss";
@import "/src/styles/custom-scss/mixins/custom-scrollbar.scss";
cx-page-layout.header {
  cx-page-slot.NavigationBar {
    @include mobile {
      display: none;
      app-custom-category-navigation {
        display: none;
      }
    }
    @include desktop {
      overflow-x: auto;
      min-height: 3.75rem;
      @include custom-scrollbar() {
        &::-webkit-scrollbar {
          height: 0.25rem;
        }
        &::-webkit-scrollbar-track-piece {
          background-color: transparent;
        }
        scrollbar-color: transparent transparent;
        &::-webkit-scrollbar-track {
          background-color: #212121;
          border-radius: 1.25rem;
        }
        &::-webkit-scrollbar-thumb {
          background-color: transparent;
        }
      }
      &:hover {
        overflow-x: auto;
        @include custom-scrollbar() {
          &::-webkit-scrollbar {
            height: 0.25rem;
          }
          &::-webkit-scrollbar-track {
            background-color: #212121;
            border-radius: 1.25rem;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #919191;
            border: none;
          }
          &::-webkit-scrollbar-thumb:hover {
            background-color: #919191;
          }
          scrollbar-color: #919191 #212121;
        }
      }
    }
    @include custom-small-desktop {
      min-height: 2.6875rem;
    }
    @include large-desktop {
      min-height: 2.6875rem;
    }
  }
}
