@import "src/styles/custom-scss/mixins/media-queries.scss";

cx-storefront {
  &.LoginHeaderLessPageTemplate {
    header {
      display: none;
    }
  }
}

cx-page-layout.LoginHeaderLessPageTemplate {
  padding-top: 6rem;
  padding-bottom: 10rem;

  @include custom-small-desktop {
    padding-top: 7.25rem;
    padding-bottom: 5.9375rem;
  }

  @include mobile {
    padding-top: 1rem;
    padding-bottom: 3rem;
  }

  .LeftContentSlot {
    @include mobile {
      padding: 0.75rem 1.5rem 5rem 1.5rem;
    }

    flex-direction: column;
    align-items: center;

    cx-banner {
      margin: 0 auto 0.75rem auto;
      max-width: 27.5rem;

      cx-generic-link {
        a {
          cx-media {
            width: 100%;
            display: flex;

            img {
              max-width: 13rem;
              margin: 0 auto;
              cursor: pointer;
              color: var(--cx-color-secondary);
              width: 12.9375rem;
              height: 3.5625rem;

              @include custom-small-desktop {
                width: 11.25rem;
                height: 2.75rem;
              }
            }
          }
        }
      }
    }

    app-custom-login-form {
      &.user-form {
        max-width: 27.5rem;
        padding: 3.0625rem 3.3125rem;
        border: 0.0625rem solid var(--cx-color-grey-dark);
        border-radius: 1.25rem;
        min-height: 33.25rem;
        max-height: 35.75rem;
        color: var(--cx-color-secondary);
        font-size: 1.125rem;
        line-height: 1.8125rem;
        letter-spacing: -0.0056rem;
        position: relative;

        @include custom-small-desktop {
          max-width: 23.25rem;
          min-height: 30.8125rem;
          max-height: 30.8125rem;
          padding: 1.9375rem 2.4375rem;
        }
        @include mobile {
          max-height: 42.5rem;
        }

        .form-logo {
          position: absolute;
          top: -4.3rem;
          left: 0;

          @include custom-small-desktop {
            top: -3.8125rem;
          }
        }

        a,
        span {
          &.btn-link {
            text-decoration: underline;
            font-size: 0.875rem;
            line-height: 1.8125rem;
            letter-spacing: -0.0044rem;
            color: var(--promesa-color-neutrals-04);
            flex: 100%;
            text-align: center;

            &.forgot {
              text-align: left;
              margin-top: 0.875rem;
              margin-bottom: 1.875rem;

              @include custom-small-desktop {
                margin: 0 0 1.5rem;
                line-height: 1.1875rem;
              }
            }

            &.no-underline {
              text-decoration: none;
              margin-top: 0;
              padding-top: 1.625rem;
              border-top: 0.0625rem solid var(--cx-color-grey-dark);

              @include custom-small-desktop {
                padding-top: 1rem;
                font-size: 0.875rem;
                line-height: 1.125rem;

                .btn-link {
                  font-size: 0.875rem;
                  line-height: 1.125rem;
                }
              }

              @include mobile {
                position: unset;
                padding-top: 1rem;
              }
            }

            &.text-intermediate {
              text-decoration: none;

              > span {
                text-decoration: underline;
              }

              @include mobile {
                padding-top: 1rem;
              }

              @media (max-width: 23.5rem) {
                padding-top: 0.5rem;
              }
            }
          }
        }

        button {
          font-size: 1.375rem;
          font-weight: 500;
          line-height: 1.8125rem;
          letter-spacing: -0.0069rem;
          max-height: unset;
          height: 3.25rem;
          display: flex;
          justify-content: flex-end;

          @include custom-small-desktop {
            margin: 0;
            height: 3rem;
            font-size: 1rem;
            line-height: 1.3125rem;
            letter-spacing: -0.005rem;
            border-radius: 0.3125rem;
          }

          cx-icon {
            width: 40%;
            text-align: right;
            padding-right: 0.5rem;
            font-size: 0.8rem;
            left: 0;

            @include custom-small-desktop {
              padding-right: 1.1875rem;
              left: 0;
              font-size: 0.75rem;
            }
          }
        }
      }
    }
  }
}
