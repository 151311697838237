@import "src/styles/custom-scss/mixins/media-queries.scss";
cx-generic-link,
button {
  &.custom-button {
    font-family: var(--promesa-font-primary);
    font-weight: 500;
    &.primary {
      background: var(--cx-color-primary);
      border-radius: 0.3125rem;
      color: var(--promesa-color-neutrals-01);
      padding: 0 1.25rem;
      min-height: 2.625rem;
      border: none;
      font-size: 1.125rem;
      line-height: 1.375rem;
      text-align: left;
      box-shadow: none;
      @include custom-small-desktop {
        font-size: 1rem;
        padding: 0 1rem;
      }
      &:hover {
        background: var(--cx-color-dark);
        text-decoration: none;
      }
      &:disabled {
        background: var(--cx-color-grey-dark);
        border-color: var(--cx-color-grey-dark);
        cursor: not-allowed;
        &:hover {
          background: var(--cx-color-grey-dark);
          border-color: var(--cx-color-grey-dark);
          cursor: not-allowed;
        }
      }
    }
  }
}
a.custom-button.primary {
  color: var(--cx-color-white);
  &:hover {
    text-decoration: none;
  }
}

button,
.btn,
.btn-primary,
.btn-secondary,
.custom-button {
  transition: all 300ms ease-out;
  &:focus {
    outline-style: none;
  }
}
button.custom-button.primary {
  &:focus {
    background-color: var(--cx-color-primary);
    color: var(--promesa-color-neutrals-01);
    border-color: var(--cx-color-primary);
  }
}

.btn-primary:focus {
  background-color: var(--cx-color-primary);
  border-color: var(--cx-color-primary);
}

.btn {
  text-transform: none !important;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}
