@mixin custom-scrollbar {
  &::-webkit-scrollbar,
  ::-webkit-scrollbar {
    width: 1.0625rem;
  }
  &::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb {
    background-color: #c7cfd1f8;
    border-radius: 1.25rem;
    border: 0.4375rem solid transparent;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }

  @content;
}

@mixin hidden-scrollbar {
  &::-webkit-scrollbar,
  ::-webkit-scrollbar {
    width: 0rem;
  }
}
