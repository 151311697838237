.ng-select .ng-select-container .ng-value-container .ng-input > input {
  background: unset !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input:focus {
  background: unset !important;
}

.ng-select .ng-select-container,
.ng-select.ng-select-single .ng-select-container {
  background: unset !important;
  min-height: unset !important;
  padding: unset !important;
  line-height: 2 !important;
  height: 100%;
  @media screen and (max-width: 25.625rem) {
    line-height: 2.5 !important;
  }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  position: absolute;
  top: unset !important;
  left: 0;
  width: 100%;
}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: none;
}

.ng-select-focused {
  outline: none;
}
.ng-select .ng-select-container {
  border-radius: 0.3125rem !important;
  border: none !important;
}

.search-refiner form ng-select:focus,
.search-refiner-accordion form ng-select:focus {
  outline: none;
}

.ng-dropdown-panel,
.ng-select.ng-select-opened .ng-select-container {
  z-index: 10 !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow,
.ng-select.ng-select-single .ng-arrow-wrapper .ng-arrow {
  border-color: var(--cx-color-secondary) !important;
  border-style: solid !important;
  border-width: 0 0.125rem 0.125rem 0rem !important;
  padding: 0.1875rem !important;
  transform: rotate(45deg) !important;
  -webkit-transform: rotate(45deg) !important;
  top: -0.125rem;
}

.select-blue-arrow .ng-select .ng-arrow-wrapper .ng-arrow,
.ng-select.ng-select-single .ng-arrow-wrapper .ng-arrow {
  border-color: var(--cx-color-primary) !important;
  width: 0.625rem;
  height: 0.625rem;
}

input.ng-invalid.ng-dirty,
input.ng-invalid.ng-touched,
input[type="checkbox"].ng-invalid.ng-dirty,
input[type="checkbox"].ng-invalid.ng-touched,
input[type="radio"].ng-invalid.ng-dirty,
input[type="radio"].ng-invalid.ng-touched,
textarea.ng-invalid.ng-dirty,
textarea.ng-invalid.ng-touched {
  color: var(--cx-color-danger);
}

input,
input[type="checkbox"],
input[type="radio"],
textarea {
  &.ng-invalid {
    &.ng-dirty,
    &.ng-touched {
      border-color: initial;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  &:focus {
    background-color: var(--cx-color-white);
  }
}
