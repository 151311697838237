@import "src/styles/custom-scss/mixins/media-queries.scss";

cx-page-layout.navigation {
  @include mobile {
    cx-page-slot.SiteLogin {
      app-custom-login {
        .login-container,
        a {
          cx-icon[type="MY_ACCOUNT"] {
            display: none;
          }
        }
        .loggedin {
          .user-name {
            display: none;
          }
          cx-page-slot.HeaderLinks {
            cx-navigation {
              cx-navigation-ui {
                &.flyout {
                  color: var(--cx-color-white);
                  font-size: 1.125rem;
                  > nav {
                    > cx-generic-link,
                    span {
                      text-transform: capitalize;
                      font-weight: 400;
                      border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
                      padding: 1rem 1.5rem;
                      &:hover {
                        color: var(--cx-color-white);
                      }
                      cx-icon {
                        color: var(--cx-color-primary);
                      }
                    }
                    &.is-open {
                      cx-icon {
                        color: var(--cx-color-white);
                        transform: rotate(180deg);
                      }
                    }
                  }
                }
                nav {
                  span[tabindex="0"] {
                    border-bottom: none;
                  }
                  .wrapper {
                    color: var(--cx-color-white);
                    background: rgba(19, 40, 71, 0.5);
                    nav > cx-generic-link,
                    span {
                      text-transform: capitalize;
                      font-weight: 400;
                      border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
                      padding: 0 1.8rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
      a[role="link"] {
        padding: 1rem 1.4rem;
        display: block;
      }
    }
  }
}

header {
  &.is-expanded {
    cx-page-layout {
      &.navigation {
        @include mobile {
          top: 0;
          height: 100dvh;
          padding-top: 5.625rem;
          overflow-y: auto;
        }
      }
    }
  }
}
