@import "src/styles/custom-scss/mixins/media-queries.scss";

cx-storefront {
  &.ErrorPageTemplate {
    cx-page-layout {
      &.ErrorPageTemplate {
        cx-page-slot {
          &.MiddleContent {
            flex-direction: column;

            app-custom-paragraph {
              max-width: 36.125rem;
              margin: auto;
              cursor: default;

              @include custom-small-desktop {
                max-width: 25.875rem;
              }

              @include mobile {
                max-width: 100%;

                &:nth-child(2) {
                  max-width: 20.125rem;
                  text-align: center;
                }
              }

              p h2 {
                font-size: 3.5rem;
                line-height: 4rem;
                font-weight: 500;
                letter-spacing: -0.0175rem;
                color: var(--cx-color-secondary);
                margin-bottom: 0.25rem;
                margin-top: 8.4375rem;

                @include custom-small-desktop {
                  font-size: 2.5rem;
                  line-height: 3.15625rem;
                  margin-top: 5rem;
                  margin-bottom: 0.5rem;
                  letter-spacing: -0.0125rem;
                }

                @include mobile {
                  font-size: 2rem;
                  line-height: 2.375rem;
                  letter-spacing: -0.01rem;
                  margin-top: 7.125rem;
                  margin-bottom: 1rem;
                }
              }
            }

            p {
              margin-bottom: 0;
              font-size: 1.25rem;
              line-height: 1.625rem;
              letter-spacing: -0.0063rem;
              color: var(--cx-color-secondary);

              @include custom-small-desktop {
                font-size: 1rem;
                line-height: 1.3125rem;
                letter-spacing: -0.005rem;
              }

              @include mobile {
                font-size: 1rem;
                line-height: 1.3125rem;
                letter-spacing: -0.005rem;
              }
            }
          }

          &.BottomContent {
            margin-top: 3rem;
            flex-direction: column;
            min-height: 3.25rem;
            margin-bottom: 25.5625rem;

            @include custom-small-desktop {
              margin-top: 2rem;
              min-height: 3rem;
              margin-bottom: 10.75rem;
            }

            @include mobile {
              margin-top: 3.25rem;
              margin-bottom: 22.75rem;
            }

            cx-link {
              margin: auto;
              width: 15.125rem;
              max-width: 15.125rem;
              height: 3.25rem;
              background: var(--cx-color-primary);
              border-radius: 0.3125rem;
              text-decoration: none;
              font-size: 1.375rem;
              line-height: 1.8125rem;
              font-weight: 500;
              letter-spacing: -0.0069rem;
              color: var(--cx-color-white);
              display: grid;
              place-content: center;

              @include custom-small-desktop {
                width: 12rem;
                max-width: 12rem;
                height: 3rem;
              }

              &:hover {
                cursor: pointer;
                filter: brightness(calc(84 / 100));
              }

              cx-generic-link {
                @include custom-small-desktop {
                  font-size: 1rem;
                  line-height: 1.3125rem;
                  height: 1.5rem;
                }

                a {
                  position: relative;

                  @include custom-small-desktop {
                    font-size: 1rem;
                    line-height: 1.3125rem;
                    height: 1.5rem;
                  }

                  &:hover {
                    color: var(--cx-color-white);
                  }

                  &::after {
                    content: "";
                    position: absolute;
                    display: inline-block;
                    width: 0.55rem;
                    height: 0.55rem;
                    border-color: var(--cx-color-white);
                    border-style: solid;
                    border-width: 0rem 0.125rem 0.125rem 0rem;
                    padding: 0.1875rem;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(-45deg);
                    top: 0.5625rem;
                    right: -2.1875rem;

                    @include custom-small-desktop {
                      top: 0.375rem;
                      right: -1.5625rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
