%AccountPageTemplate {
  cx-page-slot {
    &.SideContent {
      @include mobile {
        display: none;
      }
    }

    &.BodyContent {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      align-items: flex-start;
      padding: 0;

      @include custom-small-desktop {
        row-gap: 1.5rem;
      }

      @include mobile {
        row-gap: 1.5rem;
      }

      app-custom-order-detail-actions {
        display: none;
      }

      app-custom-order-detail-shipping {
        max-width: 91.5rem;

        app-custom-order-detail-actions {
          display: block;
        }
      }

      app-custom-order-detail-items {
        max-width: 91.5rem;
        width: 100%;

        @include mobile {
          padding: 0 1.1875rem;
        }

        .custom-order-details__totals {
          @include custom-small-desktop {
            max-width: 19.75rem;
          }
        }

        app-custom-order-detail-totals {
          display: block;
        }
      }

      app-custom-order-detail-totals {
        display: none;
      }
    }
  }
}
