@import "/src/styles/custom-scss/mixins/media-queries.scss";

cx-pagination {
  a {
    color: var(--promesa-color-neutrals-04);
    width: 2.625rem;
    height: 2.625rem;
    border: none;
    box-sizing: content-box;
    border-inline-end: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;
    font-family: var(--promesa-font-primary);
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: -0.0056rem;
    transition: all 300ms ease-out;

    @include custom-small-desktop {
      width: 2rem;
      height: 2rem;
      font-size: 0.875rem;
    }

    &:hover {
      color: var(--cx-color-dark);
    }

    &:last-child {
      border-inline-end: none;
    }

    &.page {
      &.current {
        background-color: var(--cx-color-dark);
        border-color: var(--cx-color-dark);
        color: var(--cx-color-white);
        opacity: 1;
        border-radius: 0.3125rem;

        &:hover {
          background-color: var(--cx-color-primary);
          border-color: var(--cx-color-primary);
        }
      }
    }

    &.previous,
    &.next {
      color: var(--promesa-color-neutrals-04);
      font-size: 3rem;
      margin: 0 1rem;

      @include mobile {
        font-size: 3.5rem;
      }

      @include custom-small-desktop {
        font-size: 1.5rem;
        margin: 0 0.25rem;
      }

      &:hover {
        color: var(--cx-color-dark);
      }
    }
  }
}
