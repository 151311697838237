cx-form-errors {
  p {
    color: var(--cx-color-danger);
    padding-inline-start: 0;
    text-align: left;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: -0.0044rem;
    position: absolute;
    padding: 0;
    margin: 0;

    @include custom-small-desktop {
      font-size: 0.8125rem;
      line-height: 1rem;
    }

    @include mobile {
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.875rem;
      color: var(--promesa-color-system-01);
      margin-top: 0.25rem;
    }

    &::after,
    &::before {
      display: none;
    }
  }
}
