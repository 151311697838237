@import "src/styles/custom-scss/mixins/media-queries.scss";

app-custom-login-form {
  &.user-form {
    max-width: 19.875rem;
    padding: 0 1.1875rem 1.75rem 1.25rem;
    width: 100%;
    max-height: 30.1875rem;
    color: var(--cx-color-secondary);
    font-size: 1.125rem;
    line-height: 1.8125rem;
    letter-spacing: -0.0056rem;

    .login-form__body {
      width: 100%;
    }

    @include custom-small-desktop {
      padding: 0 1.25rem 1rem 1.25rem;
      font-size: 1rem;
      line-height: 1.125rem;
      min-height: 25.9375rem;
      align-items: flex-start;
    }

    @include mobile {
      max-width: 100%;
      margin: 0;
      padding: 3rem 1.25rem 1.75rem 1.25rem;
      height: calc(100% - 5.625rem);
      position: initial;
      row-gap: 0;

      .login-form__body {
        display: block;
        width: 100%;
        max-height: 22.625rem;
        height: 100%;
        max-width: 21.75rem;
        margin: 0 auto;
      }
    }

    .btn-link {
      text-decoration: underline;
      font-size: 0.875rem;
      line-height: 1.1875rem;
      letter-spacing: -0.0044rem;
      color: var(--promesa-color-neutrals-04);
      flex: 100%;
      text-align: center;

      &.text-intermediate {
        margin: 1rem;
        text-decoration: none;

        @media (min-width: 26.75rem) and (max-width: 62rem) {
          display: flex !important;
          column-gap: 0.25rem;
          justify-content: center;
          height: 2.625rem;
          align-items: center;
          margin-top: 0;

          span {
            margin: 0 !important;
          }

          .btn-link {
            width: fit-content;
            max-width: fit-content;
          }
        }

        @media screen and (max-width: 26.6875rem) {
          width: 100%;
          margin: 0;
          height: 2.625rem;
          align-items: center;
          margin-bottom: 2.625rem !important;
          margin-top: 0;
          .btn-link-register {
            width: 100%;
            max-width: 100%;
            line-height: 1.125rem !important;
          }
        }
      }

      &.no-underline {
        text-decoration: none;
        margin-top: 0;
        padding-top: 1rem;
        border-top: 0.0625rem solid var(--cx-color-grey-dark);

        @include custom-small-desktop {
          margin-top: 0;
          padding-top: 1rem;
        }

        @include mobile {
          margin-top: 0;
          padding-top: 2rem;
          padding-bottom: 2rem;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          line-height: 1.1875rem;
        }
      }

      &.forgot {
        @include mobile {
          margin-top: 2rem;
        }
      }
    }

    button {
      font-size: 1.375rem;
      font-weight: 500;
      line-height: 1.8125rem;
      letter-spacing: -0.0069rem;
      max-height: unset;
      height: 3.25rem;
      display: flex;
      justify-content: center;
      align-items: center;

      @include custom-small-desktop {
        height: 2.625rem;
        font-size: 1rem;
        line-height: 1rem;
      }

      @include mobile {
        margin-top: 3rem;
        display: flex;
        justify-content: flex-end;
      }

      @media screen and (max-height: 41.6875rem) {
        margin-top: 1rem;
      }

      @media screen and (max-width: 26.75rem) {
        margin-top: 2rem;
      }

      @media screen and (max-width: 23.375rem) {
        margin-top: 1rem;
      }

      cx-icon {
        font-size: 0.8rem;
        position: relative;
        left: 4.6875rem;

        @include mobile {
          position: unset;
          width: 40%;
          text-align: right;
          padding-right: 0.5rem;
        }
      }
    }
  }
}
