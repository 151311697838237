@import "/src/styles/custom-scss/mixins/media-queries.scss";

.custom-banner-carousel {
  .swiper-pagination {
    text-align: start;
    padding-left: 2.5rem;
    padding-bottom: 2.25rem;
    bottom: 0.625rem;
    left: 0;
    width: 100%;
    @include custom-small-desktop {
      padding-left: 2rem;
      padding-bottom: 1rem;
    }
    @include mobile {
      text-align: center;
      padding-left: 0;
      padding-bottom: 0;
      bottom: -0.4rem;
    }
    .swiper-pagination-bullet {
      background-color: var(--cx-color-secondary);
      background: var(--cx-color-secondary);
      opacity: 1;

      @include custom-small-desktop {
        width: 0.625rem;
        height: 0.625rem;
        margin: 0;
      }

      &:not(:last-child) {
        margin-right: 0.4375rem;
      }

      &-active {
        background-color: var(--cx-color-primary);
      }
    }
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0.625rem;
    left: 0;
    width: 100%;
    @include mobile {
      bottom: -0.4rem;
    }
  }

  .swiper-container {
    min-height: 18.75rem;
    @include mobile {
      min-height: unset;
      padding-bottom: 1.5rem;
    }
  }
}
