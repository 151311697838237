@import 'src/styles/custom-scss/mixins/media-queries.scss';
cx-page-layout.header{
  @include mobile{
    cx-page-slot{
      &.SiteContext, &.SiteLinks{
        display: none;
      }
    }
  }
}
