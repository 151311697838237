@import "src/styles/custom-scss/mixins/media-queries.scss";
@import "../placeholders/header-small-desktop.scss";

.header {
  cx-page-slot.SiteLogo {
    max-height: 5.625rem;

    @include custom-small-desktop {
      margin: 0;
    }
    @include large-desktop {
      margin: 0;
    }

    @include mobile {
      height: auto;
    }
    img {
      width: 10rem;
      height: 3.125rem;
      margin-left: 0;

      @include custom-small-desktop {
        width: 10rem;
        height: 3.125rem;
        margin-top: 0.3125rem;
      }

      @include large-desktop {
        width: 10rem;
        height: 3.125rem;
      }

      @include mobile {
        width: 8.6875rem;
        height: 2.5rem;
      }
    }

    .loggedin {
      cx-icon {
        @include mobile {
          padding-right: 2.625rem;
        }
      }
    }

    @include desktop {
      width: 9.5%;
    }

    @include custom-small-desktop {
      width: 10rem !important;
    }
    @include large-desktop {
      width: 10rem !important;
      padding: 0 !important;
    }

    @media screen and (min-width: $xxl) {
      padding-right: 2rem;
    }

    @include mobile {
      img {
        max-width: 8.75rem;
        height: 100%;
        margin-left: 0;

        @media screen and (max-width: 23.125rem) {
          max-width: 5rem;
        }
      }
    }

    @include tablet {
      margin-right: 9%;
    }
  }
}
