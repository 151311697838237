@import "src/styles/custom-scss/mixins/media-queries.scss";

header {
  .header {
    .PreHeader {
      cx-hamburger-menu {
        &button {
          .hamburger-box {
            width: 1.75rem !important;
            height: 0.75rem !important;
          }
        }
      }

      .hamburger.is-active .hamburger-inner,
      .is-active.cx-hamburger .hamburger-inner,
      .hamburger.is-active .hamburger-inner::before,
      .is-active.cx-hamburger .hamburger-inner::before,
      .hamburger.is-active .hamburger-inner::after,
      .is-active.cx-hamburger .hamburger-inner::after {
        background-color: var(--cx-color-primary);
      }
    }
  }
}
