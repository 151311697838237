@import "src/styles/custom-scss/mixins/media-queries.scss";

body.custom-landing-promeclub {
  cx-page-layout {
    &.ContentPage1Template {
      padding: 0;
      overflow: hidden;
      position: relative;

      app-custom-simple-responsive-banner {
        width: -webkit-fill-available;

        .content {
          display: none;
        }
      }
    }
  }
  .footer {
    @include desktop {
      margin-top: 0rem;
    }
  }
}
