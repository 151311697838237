cx-org-list .btn, cx-org-list button.button,
cx-org-list a.button, cx-org-list a.link,
cx-org-list button.link {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  cx-org-list .btn, cx-org-list button.button,
cx-org-list a.button, cx-org-list a.link,
cx-org-list button.link {
    transition: none;
  }
}
cx-org-list .btn:hover, cx-org-list button.button:hover,
cx-org-list a.button:hover, cx-org-list a.link:hover,
cx-org-list button.link:hover {
  color: #212529;
  text-decoration: none;
}
cx-org-list .btn:focus, cx-org-list button.button:focus,
cx-org-list a.button:focus, cx-org-list a.link:focus,
cx-org-list button.link:focus, cx-org-list .btn.focus, cx-org-list button.focus.button,
cx-org-list a.focus.button, cx-org-list a.focus.link,
cx-org-list button.focus.link {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
cx-org-list .btn.disabled, cx-org-list button.disabled.button,
cx-org-list a.disabled.button, cx-org-list a.disabled.link,
cx-org-list button.disabled.link, cx-org-list .btn:disabled, cx-org-list button.button:disabled,
cx-org-list a.button:disabled, cx-org-list a.link:disabled,
cx-org-list button.link:disabled {
  opacity: 0.65;
}
cx-org-list .btn:not(:disabled):not(.disabled), cx-org-list button.button:not(:disabled):not(.disabled),
cx-org-list a.button:not(:disabled):not(.disabled), cx-org-list a.link:not(:disabled):not(.disabled),
cx-org-list button.link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
cx-org-list a.btn.disabled,
cx-org-list a.disabled.button, cx-org-list a.disabled.link,
cx-org-list fieldset:disabled a.btn,
cx-org-list fieldset:disabled a.button,
cx-org-list fieldset:disabled a.link {
  pointer-events: none;
}
cx-org-list .btn-primary, cx-org-list a.button.primary,
cx-org-list a.button.primary.disabled,
cx-org-list a.button.primary:not(:disabled):not(.disabled),
cx-org-list button.button.primary,
cx-org-list button.button.primary.disabled,
cx-org-list button.button.primary:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
cx-org-list .btn-primary:hover, cx-org-list a.button.primary:hover,
cx-org-list a.button.primary:hover:not(:disabled):not(.disabled),
cx-org-list button.button.primary:hover,
cx-org-list button.button.primary:hover:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
cx-org-list .btn-primary:focus, cx-org-list a.button.primary:focus,
cx-org-list a.button.primary:focus:not(:disabled):not(.disabled),
cx-org-list button.button.primary:focus,
cx-org-list button.button.primary:focus:active:not(:disabled):not(.disabled), cx-org-list .btn-primary.focus, cx-org-list a.focus.button.primary,
cx-org-list a.focus.button.primary:not(:disabled):not(.disabled),
cx-org-list button.focus.button.primary,
cx-org-list button.focus.button.primary:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
cx-org-list .btn-primary.disabled, cx-org-list a.disabled.button.primary,
cx-org-list a.disabled.button.primary:not(:disabled):not(.disabled),
cx-org-list button.disabled.button.primary,
cx-org-list button.disabled.button.primary:active:not(:disabled):not(.disabled), cx-org-list .btn-primary:disabled, cx-org-list a.button.primary:disabled,
cx-org-list a.button.primary:disabled:not(:disabled):not(.disabled),
cx-org-list button.button.primary:disabled,
cx-org-list button.button.primary:disabled:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
cx-org-list .btn-primary:not(:disabled):not(.disabled):active, cx-org-list a.button.primary:not(:disabled):not(.disabled):active,
cx-org-list button.button.primary:not(:disabled):not(.disabled):active, cx-org-list .btn-primary:not(:disabled):not(.disabled).active, cx-org-list a.button.primary:not(:disabled):not(.disabled).active,
cx-org-list button.button.primary:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-primary.dropdown-toggle, .show > cx-org-list a.dropdown-toggle.button.primary,
.show > cx-org-list button.dropdown-toggle.button.primary {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
cx-org-list .btn-primary:not(:disabled):not(.disabled):active:focus, cx-org-list a.button.primary:not(:disabled):not(.disabled):active:focus,
cx-org-list button.button.primary:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-primary:not(:disabled):not(.disabled).active:focus, cx-org-list a.button.primary:not(:disabled):not(.disabled).active:focus,
cx-org-list button.button.primary:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-primary.dropdown-toggle:focus, .show > cx-org-list a.dropdown-toggle.button.primary:focus,
.show > cx-org-list button.dropdown-toggle.button.primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
cx-org-list .btn-secondary, cx-org-list button.button.secondary,
cx-org-list a.button.secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
cx-org-list .btn-secondary:hover, cx-org-list button.button.secondary:hover,
cx-org-list a.button.secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
cx-org-list .btn-secondary:focus, cx-org-list button.button.secondary:focus,
cx-org-list a.button.secondary:focus, cx-org-list .btn-secondary.focus, cx-org-list button.focus.button.secondary,
cx-org-list a.focus.button.secondary {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
cx-org-list .btn-secondary.disabled, cx-org-list button.disabled.button.secondary,
cx-org-list a.disabled.button.secondary, cx-org-list .btn-secondary:disabled, cx-org-list button.button.secondary:disabled,
cx-org-list a.button.secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
cx-org-list .btn-secondary:not(:disabled):not(.disabled):active, cx-org-list button.button.secondary:not(:disabled):not(.disabled):active,
cx-org-list a.button.secondary:not(:disabled):not(.disabled):active, cx-org-list .btn-secondary:not(:disabled):not(.disabled).active, cx-org-list button.button.secondary:not(:disabled):not(.disabled).active,
cx-org-list a.button.secondary:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-secondary.dropdown-toggle, .show > cx-org-list button.dropdown-toggle.button.secondary,
.show > cx-org-list a.dropdown-toggle.button.secondary {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
cx-org-list .btn-secondary:not(:disabled):not(.disabled):active:focus, cx-org-list button.button.secondary:not(:disabled):not(.disabled):active:focus,
cx-org-list a.button.secondary:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-secondary:not(:disabled):not(.disabled).active:focus, cx-org-list button.button.secondary:not(:disabled):not(.disabled).active:focus,
cx-org-list a.button.secondary:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-secondary.dropdown-toggle:focus, .show > cx-org-list button.dropdown-toggle.button.secondary:focus,
.show > cx-org-list a.dropdown-toggle.button.secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
cx-org-list .btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
cx-org-list .btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
cx-org-list .btn-success:focus, cx-org-list .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
cx-org-list .btn-success.disabled, cx-org-list .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
cx-org-list .btn-success:not(:disabled):not(.disabled):active, cx-org-list .btn-success:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
cx-org-list .btn-success:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-success:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
cx-org-list .btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
cx-org-list .btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
cx-org-list .btn-info:focus, cx-org-list .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
cx-org-list .btn-info.disabled, cx-org-list .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
cx-org-list .btn-info:not(:disabled):not(.disabled):active, cx-org-list .btn-info:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
cx-org-list .btn-info:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-info:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
cx-org-list .btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
cx-org-list .btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
cx-org-list .btn-warning:focus, cx-org-list .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
cx-org-list .btn-warning.disabled, cx-org-list .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
cx-org-list .btn-warning:not(:disabled):not(.disabled):active, cx-org-list .btn-warning:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
cx-org-list .btn-warning:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-warning:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
cx-org-list .btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
cx-org-list .btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
cx-org-list .btn-danger:focus, cx-org-list .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
cx-org-list .btn-danger.disabled, cx-org-list .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
cx-org-list .btn-danger:not(:disabled):not(.disabled):active, cx-org-list .btn-danger:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
cx-org-list .btn-danger:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-danger:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
cx-org-list .btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
cx-org-list .btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
cx-org-list .btn-light:focus, cx-org-list .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
cx-org-list .btn-light.disabled, cx-org-list .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
cx-org-list .btn-light:not(:disabled):not(.disabled):active, cx-org-list .btn-light:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
cx-org-list .btn-light:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-light:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
cx-org-list .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
cx-org-list .btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
cx-org-list .btn-dark:focus, cx-org-list .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
cx-org-list .btn-dark.disabled, cx-org-list .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
cx-org-list .btn-dark:not(:disabled):not(.disabled):active, cx-org-list .btn-dark:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
cx-org-list .btn-dark:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-dark:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
cx-org-list .btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
cx-org-list .btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
cx-org-list .btn-outline-primary:focus, cx-org-list .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
cx-org-list .btn-outline-primary.disabled, cx-org-list .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
cx-org-list .btn-outline-primary:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-primary:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
cx-org-list .btn-outline-primary:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
cx-org-list .btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
cx-org-list .btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
cx-org-list .btn-outline-secondary:focus, cx-org-list .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
cx-org-list .btn-outline-secondary.disabled, cx-org-list .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
cx-org-list .btn-outline-secondary:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
cx-org-list .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
cx-org-list .btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
cx-org-list .btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
cx-org-list .btn-outline-success:focus, cx-org-list .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
cx-org-list .btn-outline-success.disabled, cx-org-list .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
cx-org-list .btn-outline-success:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-success:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
cx-org-list .btn-outline-success:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
cx-org-list .btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
cx-org-list .btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
cx-org-list .btn-outline-info:focus, cx-org-list .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
cx-org-list .btn-outline-info.disabled, cx-org-list .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
cx-org-list .btn-outline-info:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-info:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
cx-org-list .btn-outline-info:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
cx-org-list .btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
cx-org-list .btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
cx-org-list .btn-outline-warning:focus, cx-org-list .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
cx-org-list .btn-outline-warning.disabled, cx-org-list .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
cx-org-list .btn-outline-warning:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-warning:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
cx-org-list .btn-outline-warning:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
cx-org-list .btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
cx-org-list .btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
cx-org-list .btn-outline-danger:focus, cx-org-list .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
cx-org-list .btn-outline-danger.disabled, cx-org-list .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
cx-org-list .btn-outline-danger:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-danger:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
cx-org-list .btn-outline-danger:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
cx-org-list .btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
cx-org-list .btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
cx-org-list .btn-outline-light:focus, cx-org-list .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
cx-org-list .btn-outline-light.disabled, cx-org-list .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
cx-org-list .btn-outline-light:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-light:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
cx-org-list .btn-outline-light:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
cx-org-list .btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
cx-org-list .btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
cx-org-list .btn-outline-dark:focus, cx-org-list .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
cx-org-list .btn-outline-dark.disabled, cx-org-list .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
cx-org-list .btn-outline-dark:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-dark:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
cx-org-list .btn-outline-dark:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
cx-org-list .btn-link, cx-org-list a.link,
cx-org-list button.link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
cx-org-list .btn-link:hover, cx-org-list a.link:hover,
cx-org-list button.link:hover {
  color: #0056b3;
  text-decoration: underline;
}
cx-org-list .btn-link:focus, cx-org-list a.link:focus,
cx-org-list button.link:focus, cx-org-list .btn-link.focus, cx-org-list a.focus.link,
cx-org-list button.focus.link {
  text-decoration: underline;
}
cx-org-list .btn-link:disabled, cx-org-list a.link:disabled,
cx-org-list button.link:disabled, cx-org-list .btn-link.disabled, cx-org-list a.disabled.link,
cx-org-list button.disabled.link {
  color: #6c757d;
  pointer-events: none;
}
cx-org-list .btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
cx-org-list .btn-sm, cx-org-list button.button,
cx-org-list a.button, cx-org-list a.link,
cx-org-list button.link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
cx-org-list .btn-block {
  display: block;
  width: 100%;
}
cx-org-list .btn-block + .btn-block {
  margin-top: 0.5rem;
}
cx-org-list input[type=submit].btn-block,
cx-org-list input[type=reset].btn-block,
cx-org-list input[type=button].btn-block {
  width: 100%;
}
cx-org-list a.link,
cx-org-list button.link {
  color: var(--cx-color-primary);
  padding: 0;
  display: flex;
  justify-content: left;
  align-items: center;
  text-decoration: none;
  font-size: 1rem;
}
cx-org-list a.link:focus,
cx-org-list button.link:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
cx-org-list a.link:hover, cx-org-list a.link:focus,
cx-org-list button.link:hover,
cx-org-list button.link:focus {
  text-decoration: none;
}
cx-org-list a.link.disabled,
cx-org-list button.link.disabled {
  color: var(--cx-color-text);
  cursor: not-allowed;
}
cx-org-list button.button,
cx-org-list a.button {
  padding: var(--cx-spatial-base);
  margin: var(--cx-spatial-base);
  font-size: 1rem;
  line-height: 1.5rem;
}
cx-org-list button.button:focus,
cx-org-list a.button:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
cx-org-list button.button:focus,
cx-org-list a.button:focus {
  box-shadow: none;
}
cx-org-list button.button.active,
cx-org-list a.button.active {
  color: var(--cx-color-primary);
}
cx-org-list button.button.icon,
cx-org-list a.button.icon {
  padding: 0;
}
cx-org-list a.link:hover,
cx-org-list button.link:hover {
  color: var(--cx-color-primary);
}
cx-org-list a.button.primary,
cx-org-list a.button.primary.disabled,
cx-org-list a.button.primary:not(:disabled):not(.disabled),
cx-org-list button.button.primary,
cx-org-list button.button.primary.disabled,
cx-org-list button.button.primary:active:not(:disabled):not(.disabled) {
  background-color: var(--cx-color-primary);
  border-color: var(--cx-color-primary);
}
cx-org-list a.button.primary:hover,
cx-org-list a.button.primary.disabled:hover,
cx-org-list a.button.primary:not(:disabled):not(.disabled):hover,
cx-org-list button.button.primary:hover,
cx-org-list button.button.primary.disabled:hover,
cx-org-list button.button.primary:active:not(:disabled):not(.disabled):hover {
  background-color: var(--cx-color-primary);
  border-color: var(--cx-color-primary);
}
cx-org-list .form-buttons {
  display: flex;
  padding: var(--cx-spatial-base);
  place-content: space-between;
}
@media (max-width: 991.98px) {
  cx-org-list .form-buttons {
    flex-direction: column;
  }
}
cx-org-list .form-buttons button,
cx-org-list .form-buttons a {
  flex: 1;
  margin: var(--cx-spatial-base);
}

html[dir=rtl] cx-org-list cx-org-message .close {
  left: 20px;
  right: auto;
}

cx-org-list cx-org-message {
  position: absolute;
  width: 100%;
}
cx-org-list cx-org-message > * {
  transition: all 0.4s;
  position: absolute;
  width: 100%;
  opacity: 0;
  z-index: 0;
  pointer-events: none;
}
cx-org-list cx-org-message > *:first-child:not(.terminated) {
  z-index: 1;
  opacity: 1;
  pointer-events: initial;
}
cx-org-list cx-org-message > *.terminated + * {
  opacity: 1;
}
cx-org-list cx-org-message > * .inner {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 40px;
}
cx-org-list cx-org-message .close {
  position: absolute;
  right: 20px;
  margin-top: -5px;
}
cx-org-list cx-org-message p {
  margin: 0;
}
cx-org-list cx-org-message cx-icon {
  align-self: flex-start;
  margin: 5px;
}
cx-org-list cx-org-message cx-org-notification {
  display: flex;
  background-color: #deeecc;
}
cx-org-list cx-org-message cx-org-notification.error {
  background-color: var(--cx-color-danger);
}
cx-org-list cx-org-message cx-org-confirmation {
  flex: 100%;
  flex-direction: column;
  width: 100%;
  display: block;
}
cx-org-list cx-org-message cx-org-confirmation.info {
  background-color: #cbe6fe;
}
cx-org-list cx-org-message cx-org-confirmation .messageTitle {
  font-weight: bold;
}
cx-org-list cx-org-message cx-org-confirmation .message {
  display: flex;
}
cx-org-list cx-org-message cx-org-confirmation .actions {
  flex: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
cx-org-list cx-org-message cx-org-confirmation .actions button {
  margin-inline-start: 20px;
}

cx-org-list.ghost .header h3 {
  color: transparent;
  background-color: var(--cx-color-ghost);
  border-radius: var(--cx-ghost-radius);
  display: inline-block;
}
cx-org-list.ghost ng-select.sort {
  min-width: 200px;
  background-color: var(--cx-color-ghost);
}
cx-org-list.ghost ng-select.sort .ng-select-container {
  visibility: hidden;
}
cx-org-list.ghost cx-table.vertical-stacked table tr:hover:not(.is-current),
cx-org-list.ghost cx-table.vertical table tr:hover:not(.is-current) {
  background: none;
}
cx-org-list.ghost cx-table.vertical-stacked table td,
cx-org-list.ghost cx-table.vertical table td {
  position: relative;
}
cx-org-list.ghost cx-table.vertical-stacked table td .text,
cx-org-list.ghost cx-table.vertical table td .text {
  display: flex;
}
@media (min-width: 768px) {
  cx-org-list.ghost cx-table.vertical-stacked table td .text,
cx-org-list.ghost cx-table.vertical table td .text {
    min-height: 74px;
  }
}
cx-org-list.ghost cx-table.vertical-stacked table td:before,
cx-org-list.ghost cx-table.vertical table td:before {
  content: "";
  position: absolute;
  width: calc(100% - 20px);
  margin-inline-start: 20px;
  height: 20px;
  background: var(--cx-color-ghost);
  border-radius: var(--cx-ghost-radius);
}
@media (min-width: 768px) {
  cx-org-list.ghost cx-table.vertical-stacked table td:before,
cx-org-list.ghost cx-table.vertical table td:before {
    top: 25px;
    left: 0;
  }
}
cx-org-list.ghost cx-table.vertical-stacked table td:last-child:before,
cx-org-list.ghost cx-table.vertical table td:last-child:before {
  width: calc(100% - 40px);
  margin-inline-end: 20px;
}
@media (max-width: 991.98px) {
  cx-org-list.ghost cx-table.vertical-stacked table tr td:before,
cx-org-list.ghost cx-table.vertical table tr td:before {
    margin-top: -10px;
    width: calc(100% - 60px);
  }
}

cx-org-list .card .header .title-bar .title h3 button, cx-org-list .title h3 button {
  border: none;
  background-color: inherit;
}
cx-org-list .card .header .title-bar .title h3 button cx-icon, cx-org-list .title h3 button cx-icon {
  pointer-events: none;
  color: var(--cx-color, var(--cx-color-info));
}

cx-org-list {
  width: 100%;
  position: relative;
}
cx-org-list .content-wrapper,
cx-org-list form {
  display: contents;
}
cx-org-list cx-split-view:not([active-view="1"]) .list table th.amount, cx-org-list cx-split-view:not([active-view="1"]) .list table th.dateRange, cx-org-list cx-split-view:not([active-view="1"]) .list table th.currency, cx-org-list cx-split-view:not([active-view="1"]) .list table th.uid, cx-org-list cx-split-view:not([active-view="1"]) .list table th.roles, cx-org-list cx-split-view:not([active-view="1"]) .list table th.limit,
cx-org-list cx-split-view:not([active-view="1"]) .list table td.amount,
cx-org-list cx-split-view:not([active-view="1"]) .list table td.dateRange,
cx-org-list cx-split-view:not([active-view="1"]) .list table td.currency,
cx-org-list cx-split-view:not([active-view="1"]) .list table td.uid,
cx-org-list cx-split-view:not([active-view="1"]) .list table td.roles,
cx-org-list cx-split-view:not([active-view="1"]) .list table td.limit {
  opacity: 0;
  width: 0.1%;
}
cx-org-list cx-split-view:not([active-view="1"]) .list table th.active,
cx-org-list cx-split-view:not([active-view="1"]) .list table td.active {
  width: 100px;
}
cx-org-list .is-empty {
  margin: 0;
  padding: 20px;
}
@media (min-width: 768px) {
  cx-org-list {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }
}
@media (max-width: 767.98px) {
  cx-org-list cx-split-view {
    width: 100vw;
  }
}
cx-org-list cx-split-view cx-view {
  height: auto;
}
cx-org-list cx-split-view cx-view:not([position="0"]) {
  align-self: flex-start;
}
@media (min-width: 992px) {
  cx-org-list cx-split-view {
    padding-top: 40px;
  }
  cx-org-list cx-split-view cx-view:not([position="0"]) {
    display: flex;
  }
  cx-org-list cx-split-view:not([active-view="1"]) cx-view[position="0"] {
    padding-inline-end: var(--cx-split-gutter);
    border-inline-end: solid 0 var(--cx-color-light);
    border-inline-end-width: calc( min(calc(var(--cx-active-view) - 1), 1) * 1px );
  }
}
cx-org-list cx-view[position="0"] > cx-table.vertical table {
  border-top: solid 1px var(--cx-color-light);
  border-bottom: solid 1px var(--cx-color-light);
}
cx-org-list .vertical-stacked table {
  table-layout: auto;
}
cx-org-list .vertical-stacked tbody {
  border-top: solid 1px var(--cx-color-light);
}
cx-org-list .vertical-stacked tbody:last-child {
  border-bottom: solid 1px var(--cx-color-light);
}
cx-org-list .vertical-stacked tbody tr:first-child > * {
  padding-top: 20px;
}
cx-org-list .vertical-stacked tbody tr:last-child > * {
  padding-bottom: 20px;
}
cx-org-list .vertical-stacked tbody th cx-table-data-cell,
cx-org-list .vertical-stacked tbody td cx-table-data-cell {
  min-height: auto;
}
cx-org-list .vertical-stacked tbody th cx-table-data-cell a,
cx-org-list .vertical-stacked tbody td cx-table-data-cell a {
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 991.98px) {
  cx-org-list .vertical-stacked tbody th:not(:first-child),
cx-org-list .vertical-stacked tbody td:not(:first-child) {
    width: 100%;
  }
  cx-org-list .vertical-stacked tbody td {
    padding-inline-start: 40px;
  }
}
cx-org-list .footer {
  display: flex;
  justify-content: flex-end;
  padding: 40px;
}
cx-org-list section + .footer {
  padding-top: 0;
}
@media (max-width: 991.98px) {
  cx-org-list cx-pagination {
    margin: 0 var(--cx-spatial-md);
  }
}
cx-org-list cx-pagination a {
  border-radius: 0;
}
cx-org-list cx-pagination a:not(.current) {
  background-color: var(--cx-color-inverse);
}
cx-org-list cx-pagination a.disabled {
  color: var(--cx-color-light);
}
cx-org-list cx-org-toggle-link-cell {
  padding-inline-start: calc((var(--cx-depth-level) + 1) * 20px);
}
cx-org-list cx-org-toggle-link-cell button.tree-item-toggle {
  margin-inline-start: -40px;
  min-width: 40px;
}
@media (max-width: 991.98px) {
  cx-org-list cx-org-toggle-link-cell {
    padding-inline-start: calc(2.5rem + var(--cx-depth-level) * 1.5rem);
  }
}

cx-org-list cx-table.vertical-stacked tbody:hover:not(.is-current), cx-org-list cx-table.vertical tr:hover:not(.is-current) {
  background-color: var(--cx-color-background);
}
cx-org-list cx-table.vertical-stacked tbody.is-current, cx-org-list cx-table.vertical tr.is-current {
  outline: solid 3px var(--cx-color-primary);
  outline-offset: -3px;
  background: var(--cx-color-background-focus);
}
cx-org-list cx-table.vertical-stacked tbody:focus-within, cx-org-list cx-table.vertical tr:focus-within {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
  outline-offset: -2px;
}
cx-org-list cx-table.vertical-stacked tbody .hide-focus-border, cx-org-list cx-table.vertical tr .hide-focus-border {
  outline: none;
}
cx-org-list cx-org-sub-list:not(.has-nested-view) cx-table.vertical th:last-child,
cx-org-list cx-org-sub-list:not(.has-nested-view) cx-table.vertical td:last-child,
cx-org-list cx-org-sub-list:not(.has-nested-view) table th:last-child,
cx-org-list cx-org-sub-list:not(.has-nested-view) table td:last-child {
  background-image: none;
}
cx-org-list cx-org-sub-list cx-table.vertical table {
  table-layout: auto;
}
cx-org-list cx-org-sub-list cx-table.vertical table thead {
  display: none;
}
cx-org-list cx-org-sub-list cx-table.vertical table tr td.actions {
  width: 1px;
}
cx-org-list cx-org-sub-list cx-table.vertical table tr td.actions:last-child {
  padding-inline-end: 30px;
  padding-inline-start: 5px;
}
cx-org-list cx-org-sub-list cx-table.vertical table tr td a.is-current {
  outline: solid 3px var(--cx-color-primary);
  outline-offset: -3px;
  background: var(--cx-color-background-focus);
}
cx-org-list cx-org-sub-list cx-table.vertical table tr th:last-child,
cx-org-list cx-org-sub-list cx-table.vertical table tr td:last-child {
  width: 1px;
}
cx-org-list cx-org-sub-list cx-table.vertical table tr th:last-child:not(:only-child),
cx-org-list cx-org-sub-list cx-table.vertical table tr td:last-child:not(:only-child) {
  max-width: none;
}
cx-org-list cx-table {
  width: 100%;
}
cx-org-list cx-table.vertical td {
  height: 74px;
}
cx-org-list cx-table.vertical td cx-table-data-cell {
  display: inline;
  align-items: center;
  height: 74px;
  padding-inline-start: 20px;
}
cx-org-list cx-table.vertical tr td:last-child, cx-org-list cx-table.vertical-stacked tbody {
  background-image: var(--list-bg);
  background-position: var(--list-bg-pos);
  background-repeat: no-repeat;
  background-size: 12px;
}
cx-org-list cx-table td:last-child a {
  padding-inline-end: 40px;
}
cx-org-list cx-table td:last-child span {
  padding-inline-end: 20px;
}
cx-org-list cx-table.vertical-stacked tbody {
  display: table;
}
cx-org-list cx-table thead {
  pointer-events: none;
}
cx-org-list cx-table thead th {
  height: 60px;
}
cx-org-list cx-table th,
cx-org-list cx-table td {
  padding: 0;
}
cx-org-list cx-table th {
  padding-inline-start: 20px;
  font-size: 14px;
}
cx-org-list cx-table td {
  font-size: 16px;
  max-width: 1px;
}
cx-org-list cx-table td .text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
cx-org-list cx-table td .text.button {
  color: var(--cx-color-primary);
}
cx-org-list cx-table td ul.text {
  margin: 0;
  padding: 0;
}
cx-org-list cx-table td ul.text li {
  display: inline-block;
  white-space: pre-wrap;
}
cx-org-list cx-table td ul.text li:not(:last-child):after {
  content: ", ";
}
cx-org-list cx-table td a {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 74px;
}
@media (min-width: 992px) {
  cx-org-list cx-table td a .text {
    padding-inline-start: 0;
  }
}
cx-org-list cx-table td a[tabindex="0"] {
  text-decoration: underline;
  color: var(--cx-color-primary);
}
cx-org-list cx-table td a[tabindex="-1"] {
  color: inherit;
  text-decoration: none;
}
cx-org-list cx-table td.actions button {
  flex: auto;
}
cx-org-list cx-table .active span {
  color: var(--cx-color-alert, var(--cx-color-danger));
}
cx-org-list cx-table .active span.is-active {
  color: var(--cx-color-success);
}

cx-org-list .list .header {
  padding: 0 6px 38px 6px;
  height: 130px;
}
cx-org-list .list .header h3 {
  text-transform: uppercase;
  margin: 0 0 23px 0;
}
@media (max-width: 991.98px) {
  cx-org-list .list .header {
    padding: 20px;
  }
}
cx-org-list .list .header .actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
cx-org-list .list .header .actions label {
  align-items: center;
  display: inline-flex;
}
cx-org-list .list .header .actions label span {
  margin-inline-end: 0.5rem;
}
cx-org-list .list .header .actions label .sort {
  margin-inline-end: auto;
  min-width: 170px;
}
cx-org-list .list .header .actions button {
  padding: 0 15px;
}
cx-org-list .list .header .actions a,
cx-org-list .list .header .actions button {
  text-transform: uppercase;
}
cx-org-list .list .header .actions ng-select .ng-select-container.ng-has-value {
  max-height: 40px;
}

cx-org-list .card {
  width: 100%;
}
@media (max-width: 767.98px) {
  cx-org-list .card {
    border: none;
  }
}

cx-org-list .card .header {
  display: flex;
  justify-content: space-between;
  background-color: var(--cx-color-inverse);
  border-bottom: solid 1px var(--cx-color-light);
  padding: 40px 30px 20px;
}
@media (min-width: 768px) {
  cx-org-list .card .header {
    height: 130px;
    align-items: center;
  }
}
cx-org-list .card .header .title-bar {
  display: flex;
  align-items: center;
  flex: 100%;
  justify-content: space-between;
}
cx-org-list .card .header h3 {
  margin: 0 0 5px 0;
  font-size: 22px;
  text-transform: uppercase;
}
cx-org-list .card .header h4 {
  font-size: 16px;
}
cx-org-list .card .header .actions {
  display: flex;
  align-items: baseline;
}
cx-org-list .card .header .actions a,
cx-org-list .card .header .actions button {
  text-transform: uppercase;
}
@media (min-width: 768px) {
  cx-org-list .card .header .actions a,
cx-org-list .card .header .actions button {
    margin-inline-start: 10px;
  }
}
@media (max-width: 767.98px) {
  cx-org-list .card .header .actions a,
cx-org-list .card .header .actions button {
    margin-inline-end: 10px;
  }
}
cx-org-list .card .header .close cx-icon {
  color: var(--cx-color-text);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
@media (max-width: 767.98px) {
  cx-org-list .card .header {
    padding: 40px 30px 25px 30px;
  }
  cx-org-list .card .header .title-bar {
    flex-wrap: wrap;
  }
  cx-org-list .card .header .actions {
    flex: 100%;
  }
}

cx-org-list .card .main {
  position: relative;
  background: var(--cx-color-background);
}

cx-org-list .card section {
  display: flex;
  margin: 40px;
  background-color: var(--cx-color-inverse);
  border: solid 1px var(--cx-color-light);
}
cx-org-list .card section.link-list {
  display: flex;
  flex-direction: column;
}
cx-org-list .card section.link-list a {
  padding: 25px 30px;
  justify-content: flex-start;
  min-height: 74px;
  background-image: var(--list-bg);
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: var(--list-bg-pos);
}
cx-org-list .card section.link-list a:not(:last-child) {
  border-bottom: solid 1px var(--cx-color-light);
}
cx-org-list .card section.link-list a:hover:not(.is-current) {
  background-color: var(--cx-color-background);
}
cx-org-list .card section.link-list a.is-current {
  outline: solid 3px var(--cx-color-primary);
  outline-offset: -3px;
  background-color: var(--cx-color-background-focus);
}

cx-org-list .details {
  padding-top: 30px;
  padding-inline-start: 30px;
  padding-inline-end: 10px;
  display: flex;
  flex-wrap: wrap;
}
cx-org-list .details .property {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  cx-org-list .details .property {
    flex: 50%;
  }
}
@media (max-width: 767.98px) {
  cx-org-list .details .property {
    flex: 100%;
  }
}
cx-org-list .details .property.full-width {
  flex: 100%;
}
cx-org-list .details label,
cx-org-list .details legend {
  font-weight: bold;
  font-size: 18px;
}
cx-org-list .details label.form-check,
cx-org-list .details fieldset {
  font-weight: normal;
  padding-inline-end: 20px;
  padding-bottom: 10px;
  margin: 0;
}
cx-org-list .details .value {
  font-size: 16px;
  padding-inline-end: 20px;
}
cx-org-list .details .value.is-active {
  color: var(--cx-color-success);
}
cx-org-list .details .value.is-inactive {
  color: var(--cx-color-alert, var(--cx-color-danger));
}
cx-org-list .details ul.value {
  margin: 0;
  padding: 0;
  padding-inline-end: 20px;
}
cx-org-list .details ul.value li {
  display: inline;
  white-space: pre-wrap;
}
cx-org-list .details ul.value li:not(:last-child):after {
  content: ", ";
}
cx-org-list cx-org-disable-info > section {
  padding: 25px 30px;
}
cx-org-list cx-org-disable-info > section ul {
  margin: 0;
}
cx-org-list cx-org-disable-info > section ul > li:only-child {
  list-style: none;
  margin-inline-start: -1.5em;
}
cx-org-list cx-org-disable-info > section > .cx-icon {
  align-self: flex-start;
  margin-top: 4px;
  color: var(--cx-color, var(--cx-color-info));
}

cx-org-list ng-select {
  font-weight: normal;
}
cx-org-list ng-select .ng-select-container {
  max-height: 48px;
}
cx-org-list form section {
  padding-bottom: 20px;
}
cx-org-list label {
  max-width: 100%;
  padding-inline-end: 20px;
  padding-bottom: 10px;
  margin: 0;
}
@media (min-width: 768px) {
  cx-org-list label {
    flex: 50%;
  }
}
@media (max-width: 767.98px) {
  cx-org-list label {
    flex: 100%;
  }
}
cx-org-list label .form-check {
  margin: 0;
}
cx-org-list .full-width {
  flex: 100%;
}
cx-org-list .form-group {
  padding-inline-end: 20px;
}
@media (min-width: 768px) {
  cx-org-list .form-group {
    flex: 50%;
  }
}
@media (max-width: 767.98px) {
  cx-org-list .form-group {
    flex: 100%;
  }
}
cx-org-list .form-group cx-form-errors {
  position: relative;
}
cx-org-list .form-group cx-form-errors p {
  padding-inline-start: 30px;
  font-size: 16px;
  font-weight: normal;
}
cx-org-list ng-select {
  font-weight: normal;
}
cx-org-list ng-select .ng-select-container {
  max-height: 48px;
}
@media (max-width: 767.98px) {
  cx-org-list .ng-dropdown-panel span {
    white-space: normal;
  }
}

cx-page-layout.CompanyPageTemplate {
  max-width: var(--cx-page-width-max);
  margin: auto;
  margin-bottom: 40px;
}
@media (max-width: 767.98px) {
  cx-page-layout.CompanyPageTemplate cx-page-slot {
    margin: 0;
  }
}
cx-page-layout.CompanyPageTemplate cx-banner cx-media {
  --cx-img-filter: invert(71%) sepia(50%) saturate(7474%) hue-rotate(329deg)
    brightness(110%) contrast(99%);
}
cx-page-layout.CompanyPageTemplate .BodyContent {
  justify-content: space-between;
}
@media (min-width: 576px) {
  cx-page-layout.CompanyPageTemplate .BodyContent {
    margin: 0 10px;
  }
}
cx-page-layout.CompanyPageTemplate cx-banner p {
  all: unset;
}
cx-page-layout.CompanyPageTemplate cx-banner {
  flex: 100%;
  margin-top: 20px;
}
@media (min-width: 768px) {
  cx-page-layout.CompanyPageTemplate cx-banner {
    flex: 0 0 calc(33% - 10px);
  }
}
@media (max-width: 991.98px) {
  cx-page-layout.CompanyPageTemplate cx-banner {
    flex: calc(50% - 40px);
    margin-inline-start: 10px;
    margin-inline-end: 10px;
  }
}
@media (max-width: 767.98px) {
  cx-page-layout.CompanyPageTemplate cx-banner {
    flex: 100%;
    margin-inline-start: 20px;
    margin-inline-end: 20px;
  }
}
cx-page-layout.CompanyPageTemplate cx-banner cx-generic-link,
cx-page-layout.CompanyPageTemplate cx-banner a {
  height: 100%;
}
cx-page-layout.CompanyPageTemplate cx-banner a {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: min-content 1fr;
  gap: 3% 30px;
  grid-template-areas: "media ." "media .";
  padding: 25px 25px 25px 30px;
  border: solid 1px var(--cx-color-light);
}
cx-page-layout.CompanyPageTemplate cx-banner a:hover {
  text-decoration: none;
  border-color: var(--cx-color-background);
  background: var(--cx-color-background);
}
cx-page-layout.CompanyPageTemplate cx-banner a cx-media {
  grid-area: media;
}
cx-page-layout.CompanyPageTemplate cx-banner a cx-media img {
  filter: var(--cx-img-filter);
  width: 50px;
  height: 50px;
}
cx-page-layout.CompanyPageTemplate cx-banner a p {
  color: var(--cx-color-text);
}
cx-page-layout.CompanyPageTemplate cx-banner a p.headline {
  font-weight: bold;
}

cx-org-unit-list {
  display: contents;
}

cx-org-list {
  --list-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 44.21 69.76'%3E%3Cpolyline fill='none' stroke='%23D3D6DA' stroke-width='10' points='5,5 35,35 5,65 '/%3E%3C/svg%3E%0A");
  --list-bg-pos: calc(100% - 26px) 50%;
}

html[dir=rtl] cx-org-list {
  --list-bg: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 44.21 69.76' %3E%3Cpolyline transform='scale (-1, 1)' transform-origin='center' fill='none' stroke='%23D3D6DA' stroke-width='10' points='5,5 35,35 5,65 '/%3E%3C/svg%3E");
  --list-bg-pos: 25px;
}

.my-company-popover .popover-details > .property {
  margin-bottom: 0.5rem;
}
.my-company-popover .popover-details > .property > label {
  font-weight: bold;
  margin-bottom: 0;
}
.my-company-popover .value {
  font-size: 0.75rem;
}

.hint-popover {
  font-size: 0.75rem;
}
.hint-popover p {
  margin: 0;
}

cx-order-approval-list .cx-order-approval-table {
  padding: 1.5rem 0 1.125rem 0;
  margin-bottom: 0;
  border-width: 1px 0 1px 0;
  border-color: var(--cx-color-light);
  border-style: solid;
}
cx-order-approval-list .cx-order-approval-table tr {
  width: 100%;
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-table tr {
    border-width: 1px 0 0 0;
    border-color: var(--cx-color-light);
    border-style: solid;
  }
  cx-order-approval-list .cx-order-approval-table tr:first-child {
    border-width: 0;
    padding: 1.25rem 0 0 0;
  }
}
cx-order-approval-list .cx-order-approval-table th {
  padding: 1.5rem 0 1.125rem 0;
  text-align: start;
}
cx-order-approval-list .cx-order-approval-table th:last-child {
  text-align: end;
}
cx-order-approval-list .cx-order-approval-table td {
  width: 15%;
  padding: 1.625rem 0;
}
@media (min-width: 768px) {
  cx-order-approval-list .cx-order-approval-table td {
    text-align: start;
  }
  cx-order-approval-list .cx-order-approval-table td:last-child {
    text-align: end;
  }
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-table td {
    width: 100%;
    display: flex;
    border-width: 0;
    padding: 0 1.25rem;
  }
  cx-order-approval-list .cx-order-approval-table td:first-child {
    padding-top: 1.25rem;
  }
  cx-order-approval-list .cx-order-approval-table td:last-child {
    padding-bottom: 1.25rem;
  }
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-thead-mobile {
    display: none;
  }
}
cx-order-approval-list .cx-order-approval-code .cx-order-approval-value {
  text-decoration: underline;
}
cx-order-approval-list .cx-order-approval-po-code,
cx-order-approval-list .cx-order-approval-date,
cx-order-approval-list .cx-order-approval-status,
cx-order-approval-list .cx-order-approval-total,
cx-order-approval-list .cx-order-approval-placed {
  text-align: center;
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-po-code,
cx-order-approval-list .cx-order-approval-date,
cx-order-approval-list .cx-order-approval-status,
cx-order-approval-list .cx-order-approval-total,
cx-order-approval-list .cx-order-approval-placed {
    text-align: start;
  }
}
cx-order-approval-list .cx-order-approval-label {
  text-transform: uppercase;
  color: var(--cx-color-secondary);
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-label {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    min-width: 110px;
  }
}
cx-order-approval-list .cx-order-approval-value {
  color: var(--cx-color-text);
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-value {
    font-size: var(--cx-font-size, 1rem);
    font-weight: 400;
    line-height: var(--cx-line-height, 1.2222222222);
  }
}
cx-order-approval-list .cx-order-approval-form-group {
  padding: 0;
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-form-group {
    padding: 1.25rem;
  }
}
cx-order-approval-list .cx-order-approval-sort.top {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  margin: 0;
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-sort.top {
    flex-direction: column;
    padding-top: 0;
  }
}
cx-order-approval-list .cx-order-approval-sort.bottom {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0 1rem 0;
  margin: 0;
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-sort.bottom {
    flex-direction: column;
    padding-top: 0;
  }
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-pagination {
    margin: 0 auto;
  }
}
cx-order-approval-list .cx-order-approval-no-order {
  font-size: var(--cx-font-size, 1rem);
  font-weight: 400;
  line-height: var(--cx-line-height, 1.2222222222);
  min-height: 415px;
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-no-order {
    min-height: 474px;
    padding-inline-start: 1.25rem;
    padding-inline-end: 1.25rem;
  }
}
cx-order-approval-list .cx-order-approval-no-order .btn, cx-order-approval-list .cx-order-approval-no-order cx-org-list a.link, cx-org-list cx-order-approval-list .cx-order-approval-no-order a.link,
cx-order-approval-list .cx-order-approval-no-order cx-org-list button.link,
cx-org-list cx-order-approval-list .cx-order-approval-no-order button.link, cx-order-approval-list .cx-order-approval-no-order cx-org-list button.button, cx-org-list cx-order-approval-list .cx-order-approval-no-order button.button,
cx-order-approval-list .cx-order-approval-no-order cx-org-list a.button,
cx-org-list cx-order-approval-list .cx-order-approval-no-order a.button {
  margin: 1.25rem 0;
}

cx-order-approval-detail-form {
  margin-bottom: 15px;
}
cx-order-approval-detail-form .cx-approval-form-header {
  padding: 15px;
  margin-bottom: 15px;
  background-color: var(--cx-color-background);
}
cx-order-approval-detail-form .cx-approval-form-header .cx-approval-form-label {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-order-approval-detail-form textarea.form-control {
  margin-bottom: 15px;
}
@media (max-width: 991.98px) {
  cx-order-approval-detail-form a.btn, cx-order-approval-detail-form cx-org-list a.link, cx-org-list cx-order-approval-detail-form a.link,
cx-order-approval-detail-form cx-org-list a.button,
cx-org-list cx-order-approval-detail-form a.button {
    margin-bottom: 15px;
  }
}
@media (max-width: 991.98px) {
  cx-order-approval-detail-form button {
    margin-bottom: 15px;
  }
}

cx-order-detail-permission-results {
  margin: 45px 0px 15px;
}
cx-order-detail-permission-results .cx-approval-header {
  padding: 15px;
  background-color: var(--cx-color-background);
}
cx-order-detail-permission-results .cx-approval-header .cx-approval-label {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-order-detail-permission-results .cx-approval-table {
  padding: 15px;
}
cx-order-detail-permission-results .cx-approval-table .cx-approval-thead-mobile {
  background-color: var(--cx-color-dark);
}
cx-order-detail-permission-results .cx-approval-table .cx-approval-thead-mobile th {
  text-align: start;
  color: var(--cx-color-inverse);
}
@media (max-width: 767.98px) {
  cx-order-detail-permission-results .cx-approval-table .cx-approval-thead-mobile {
    display: none;
  }
}
cx-order-detail-permission-results .cx-approval-table.table-striped > tr:nth-child(odd) {
  background-color: var(--cx-color-background);
}
cx-order-detail-permission-results .cx-approval-table .cx-approval-permissionCode {
  width: 25%;
}
@media (max-width: 767.98px) {
  cx-order-detail-permission-results .cx-approval-table .cx-approval-permissionCode {
    width: 100%;
  }
}
cx-order-detail-permission-results .cx-approval-table .cx-approval-approverName {
  width: 17%;
}
@media (max-width: 767.98px) {
  cx-order-detail-permission-results .cx-approval-table .cx-approval-approverName {
    width: 100%;
  }
}
cx-order-detail-permission-results .cx-approval-table .cx-approval-statusDisplay {
  width: 13%;
}
@media (max-width: 767.98px) {
  cx-order-detail-permission-results .cx-approval-table .cx-approval-statusDisplay {
    width: 100%;
  }
}
cx-order-detail-permission-results .cx-approval-table .cx-approval-table-label {
  text-transform: uppercase;
  color: var(--cx-color-secondary);
}
@media (max-width: 767.98px) {
  cx-order-detail-permission-results .cx-approval-table .cx-approval-table-label {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    min-width: 110px;
    max-width: 110px;
  }
}
@media (max-width: 767.98px) {
  cx-order-detail-permission-results .cx-approval-table td {
    width: 100%;
    display: flex;
    border-width: 0;
    padding: 0 1.25rem;
  }
  cx-order-detail-permission-results .cx-approval-table td:first-child {
    padding-top: 1.25rem;
  }
  cx-order-detail-permission-results .cx-approval-table td:last-child {
    padding-bottom: 1.25rem;
  }
}