@import "/src/styles/custom-scss/mixins/media-queries.scss";
cx-page-layout {
  &.ProductDetailsPageTemplate {
    cx-page-slot {
      &.Summary {
        custom-add-to-cart {
          @include mobile {
            app-custom-promeclub-product-points {
              display: block;
              width: 100%;
            }
            app-custom-login-form {
              justify-content: center;
              align-items: center;
              flex-direction: column;
            }
          }
        }
        app-custom-promeclub-product-points {
          @include mobile {
            display: none;
          }
        }
        app-custom-floating-add-to-cart {
          @include mobile {
            custom-add-to-cart app-custom-login-form {
              .login-form__body {
                margin: 0 0 1rem 0;
                max-height: fit-content !important;
              }
              .btn-link.text-intermediate {
                margin: 0.75rem 0 0 0 !important;
              }
            }
          }
        }
      }
    }
  }
}
