@import "src/styles/custom-scss/mixins/media-queries.scss";
@import "/src/styles/custom-scss/account-slide-variables";

cx-storefront {
  &.AccountPageTemplate {
    cx-page-layout {
      &.AccountPageTemplate {
        display: flex;

        @include desktop {
          padding: 0 4.5rem;
          align-items: flex-start;
        }

        @include custom-small-desktop {
          padding: 0 2rem;
          position: relative;
        }

        @include mobile {
          display: flex;
          flex-direction: column;
          padding-bottom: 2rem;
          button {
            margin: 0;
          }
        }

        cx-page-slot {
          &.BodyContent {
            order: 2;
            margin: 0.75rem 0 3rem 4rem;

            @include custom-small-desktop {
              margin: 1.625rem 0 3rem 3rem;
              max-width: calc(100% - 3rem);
            }

            @include mobile {
              margin: 2rem 0 0;
              display: flex;
              flex-direction: column;
            }

            app-custom-register,
            app-custom-register-customer,
            app-custom-register-non-customer,
            app-custom-register-employee {
              @include mobile {
                margin-top: -2.1875rem;
              }
            }

            app-custom-register-customer,
            app-custom-register-non-customer {
              @include desktop {
                margin-top: 2.75rem;
              }

              @include custom-small-desktop {
                margin-top: 0;
              }
            }

            app-custom-rebate-list,
            app-custom-register-customer,
            app-custom-register-non-customer,
            app-custom-claim-list {
              @include mobile {
                padding: 0 1.1875rem;
              }
            }

            app-custom-update-profile {
              &.user-form {
                --cx-max-width: 100%;
                padding: 0;

                @include custom-small-desktop {
                  margin-bottom: 2.3125rem;
                }

                @include mobile {
                  padding: 0 1.1875rem 0;
                }

                app-custom-update-password {
                  app-custom-register-customer-password {
                    .register-password {
                      padding: 0;
                      border: none;
                      height: fit-content;

                      @include mobile {
                        margin-bottom: 1rem;
                        width: 100%;
                      }

                      &__title {
                        display: none;
                      }

                      &__form-action {
                        border-top: none;
                        position: absolute;
                        justify-content: flex-start;
                        left: 0;

                        @include mobile {
                          position: unset;
                          margin: 2rem 0 0 0;
                        }
                      }

                      &__form-inputs {
                        justify-content: unset;
                        gap: 4.6875rem;

                        @include custom-small-desktop {
                          gap: 5rem;
                        }

                        @include mobile {
                          grid-gap: 1rem;
                          gap: 1rem;
                          margin-bottom: 0;
                        }

                        label {
                          max-width: 19.5625rem;
                          width: 19.5625rem;

                          @include custom-small-desktop {
                            max-width: 18rem;
                            width: 18rem;
                          }

                          @include mobile {
                            margin-bottom: 0;
                            max-width: 100%;
                            width: 100%;
                          }
                        }
                      }
                      &__form-validation {
                        @include mobile {
                          margin-bottom: 0;
                        }
                      }
                    }
                  }
                }
              }
            }

            app-custom-order-history {
              @include mobile {
                padding: 0 1.1875rem;
              }
            }

            app-custom-saved-cart-details-overview {
              app-custom-saved-cart-details-action {
                display: block;
              }
            }
            app-custom-saved-cart-details-action {
              display: none;
            }

            app-custom-saved-cart-details-items,
            app-custom-order-detail-items {
              app-custom-order-summary {
                .cx-order-summary-title_order {
                  margin-top: 0;
                  margin-bottom: 0.8rem;

                  @include custom-small-desktop {
                    font-size: 1.125rem;
                    line-height: 1.5rem;
                    letter-spacing: -0.0056rem;
                    margin-bottom: 1rem;
                  }
                }
                .cx-summary-details_order {
                  @include custom-small-desktop {
                    padding: 1.5rem;
                    row-gap: 0.25rem;
                    min-height: unset;
                  }

                  .cx-summary-label,
                  .cx-summary-amount {
                    @include custom-small-desktop {
                      font-size: 1rem;
                      line-height: 1.3125rem;
                      letter-spacing: -0.005rem;
                      height: unset;
                    }
                  }
                }

                .cx-summary-subtotal_order {
                  .cx-summary-label,
                  .cx-summary-amount {
                    @include custom-small-desktop {
                      letter-spacing: -0.005rem;
                    }
                  }
                }

                .cx-summary-iva_order {
                  @include custom-small-desktop {
                    padding: 1rem 1.5rem;
                    height: 3.5rem;
                  }

                  .cx-summary-label {
                    @include custom-small-desktop {
                      font-size: 1rem;
                      line-height: 1.3125rem;
                      letter-spacing: -0.005rem;
                    }
                  }

                  .cx-summary-amount {
                    @include custom-small-desktop {
                      font-size: 1.125rem;
                      line-height: 1.5rem;
                      letter-spacing: -0.0056rem;
                    }
                  }
                }

                .cx-summary-total_order {
                  @include custom-small-desktop {
                    height: 4rem;
                    padding: 1.25rem 1.5rem;
                    margin-bottom: 0;
                  }
                }
              }
            }
          }

          &.SideContent {
            flex: 15%;
            order: 1;
            align-items: flex-start;
            padding-top: 0;
            padding-inline-end: 0;
            padding-inline-start: 0;
            margin: inherit;
            max-width: 15.625rem;

            @include custom-small-desktop {
              height: 100%;
              position: absolute;
              left: unset;
              flex: unset;
              margin-left: -12.0625rem;
              background: white;
              transition: margin 300ms ease-in-out;
              padding-top: 1.25rem;
            }

            @include mobile {
              display: none;
            }

            app-custom-my-account {
              .my-account-container nav.active {
                @include custom-small-desktop {
                  border-right: unset;
                }
              }
            }
          }
        }
      }
    }
  }
}

body.account-slide-open
  cx-storefront.AccountPageTemplate
  cx-page-layout.AccountPageTemplate
  cx-page-slot.SideContent {
  @include custom-small-desktop {
    margin-left: 0;
  }

  app-custom-my-account {
    button.my-account-container-toggle-button {
      @include custom-small-desktop {
        cx-icon {
          transform: rotate(180deg);
          transition: 300ms ease all;
        }
      }
    }

    .my-account-container nav.active {
      @include custom-small-desktop {
        border-right: $activeNavRightBorder;
      }
    }
  }
}

body.min-height-my-account-menu
  cx-storefront.AccountPageTemplate
  cx-page-layout.AccountPageTemplate {
  @include custom-small-desktop {
    min-height: 38rem;
  }
}
