@import "src/styles/custom-scss/mixins/media-queries.scss";

cx-storefront {
  &.CartPageTemplate {
    @include mobile {
      min-height: 100vh;
    }

    cx-global-message {
      display: none;
    }

    @include mobile {
      footer {
        app-custom-footer-navigation {
          margin-bottom: 0 !important;
        }
        cx-page-slot.Footer {
          padding-bottom: 9.75rem;
          &.quotes {
            padding-bottom: 13.25rem;
          }
          &.promeclub {
            padding-bottom: 4.25rem;
          }
        }
        &.empty-cart-footer {
          padding-bottom: 0;
          margin-top: auto;
          cx-page-slot.Footer {
            padding-bottom: 0rem;
            &.promeclub {
              padding-bottom: 0rem;
            }
          }
        }
      }
    }

    cx-page-slot {
      &.BottomHeaderSlot {
        @include mobile {
          app-custom-breadcrumb {
            width: 100%;
            top: 1rem;
            height: 3rem;
            .custom-breadcrumb {
              position: absolute;
              left: 0;
              top: 0;
              margin-top: 0;
            }

            div.d-flex {
              margin-top: 2rem;

              .custom-breadcrumb__title {
                font-weight: 400;

                b {
                  text-transform: capitalize;
                }
              }
            }
          }
          app-custom-pdp-rebate {
            order: 1;
          }
        }

        @include desktop {
          app-custom-breadcrumb {
            width: 100%;
            .custom-breadcrumb {
              padding-bottom: 1.5rem;
            }
            div.d-flex {
              .custom-breadcrumb__title {
                font-weight: 400;
                margin: 0;
                b {
                  text-transform: capitalize;
                }
              }
            }
          }
        }
      }

      &.Footer {
        app-custom-footer-navigation {
          @include mobile {
            margin-bottom: 4rem;
          }
        }
      }
    }

    cx-page-layout {
      &.CartPageTemplate {
        grid-column-gap: 2.25rem;
        margin-bottom: 1.625rem;

        @include desktop {
          padding: 0.875rem 4.5rem;

          display: grid;
          grid-template-columns: auto auto 26.9375rem;
          grid-template-rows: repeat(3, auto);
          .EmptyCartMiddleContent {
            grid-area: 1 / 1 / 4 / 4;
          }
          .TopContent {
            grid-area: 1 / 1 / 3 / 3;
            justify-content: flex-start;
          }
          .CenterRightContentSlot {
            grid-area: 1 / 3 / 4 / 4;
          }
          .BottomCarrouselContentSlot {
            grid-area: 3 / 1 / 4 / 3;
          }
        }

        @include custom-small-desktop {
          padding: 0.5625rem 2rem 0;
          grid-template-columns: auto auto 21.25rem;
          grid-column-gap: 2rem;
        }

        @include mobile {
          padding: 0;
        }

        .TopContent {
          justify-content: flex-start;
          @include mobile {
            padding: 0 1rem;
          }
        }
        .BottomCarrouselContentSlot {
          @include mobile {
            padding: 0 1rem;
            margin-bottom: 0rem;
          }
        }
      }
    }
  }
}

.CartPageTemplate {
  .TopContent {
    flex-basis: 70%;

    app-custom-add-to-saved-cart {
      order: 2;
      max-height: 6rem;
      @include custom-small-desktop {
        margin-bottom: 0.375rem;
        max-height: 5rem;
      }
      @include mobile {
        max-height: 4.125rem;
      }
    }

    app-custom-rebate-carousel-cart {
      order: 3;
      @include custom-small-desktop {
        max-height: 28.0625rem;
      }
      @include desktop {
        max-height: 28.25rem;
      }
    }

    app-custom-cart-details {
      cx-item-counter:not(.readonly) input {
        border: none;
        padding: 0.75rem 0;

        @include custom-small-desktop {
          font-size: 0.875rem;
        }

        @include mobile {
          min-width: 40%;
        }
      }
    }
  }

  .CenterRightContentSlot {
    flex-basis: 25%;
    max-width: 26.9375rem;

    @include custom-small-desktop {
      max-width: 21.25rem;
    }

    @include mobile {
      max-width: 100%;
      width: 100%;
      padding: 0;
      margin-bottom: 0rem;
      position: fixed;
      bottom: 0;
      z-index: 20;
    }
    app-custom-cart-totals {
      @include mobile {
        background: var(--promesa-color-neutrals-01);
        app-custom-order-summary {
          .cx-order-summary-title {
            font-size: 1.125rem;
            font-style: normal;
            line-height: 1.5rem;
            letter-spacing: normal;
            padding: 0.5rem 1rem;
            text-align: left;
            &.mobile {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }
          .cx-summary-partials.cx-summary-partials_cart {
            .cx-summary-details.cx-summary-details_cart {
              &.hide {
                display: none;
              }
              .cx-summary-row {
                padding: 0 1rem;
                height: 2.625rem;
                .cx-summary-label {
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 1rem;
                  letter-spacing: normal;
                }
                .cx-summary-amount {
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 1.125rem;
                  letter-spacing: normal;
                }
              }
            }
            .cx-summary-subtotal_cart {
              padding: 0 1rem;
              height: 2.625rem;
              &.hide {
                display: none;
              }
              .cx-summary-label {
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1rem;
                letter-spacing: normal;
              }
              .cx-summary-amount {
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.125rem;
                letter-spacing: normal;
              }
            }
            .cx-summary-iva_cart {
              max-height: 2.625rem;
              &.hide {
                display: none;
              }
              .cx-summary-row {
                padding: 0 1rem;
                height: 2.625rem;
                .cx-summary-label {
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 1rem;
                  letter-spacing: normal;
                }
                .cx-summary-amount {
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 1.125rem;
                  letter-spacing: normal;
                }
              }
            }
          }
        }
      }
    }
    app-custom-cart-quotes {
      @include mobile {
        background: white;
        padding: 1rem 1rem 1.5rem;
      }
    }
  }
}
