@import "src/styles/custom-scss/mixins/media-queries.scss";

cx-storefront {
  &.MultiStepCheckoutSummaryPageTemplate {
    cx-page-slot {
      &.BottomHeaderSlot {
        app-custom-breadcrumb {
          @include mobile {
            height: 4.8125rem;
          }
        }
      }
      &.SiteLogo {
        &.hide {
          display: initial !important;
        }
      }
    }
    cx-page-layout.MultiStepCheckoutSummaryPageTemplate {
      column-gap: 2.5rem;
      justify-content: space-between;

      @include desktop {
        padding: 0 4.5rem 3rem;
        align-items: flex-start;
      }

      @include custom-small-desktop {
        padding: 0 2rem 3rem;
        column-gap: 2rem;
      }

      @include mobile {
        padding: 1.5rem 1rem;
        display: flex;
        background-color: var(--promesa-color-neutrals-02);
        height: 100%;
        flex-direction: column;
        margin-top: 3.5rem;
      }
    }

    cx-page-slot {
      &.SideContent {
        max-width: 26.9375rem;
        flex: 0 0 25%;
        min-width: 17.5rem;

        @include custom-small-desktop {
          width: 100%;
          min-width: 21.25rem;
        }

        @include mobile {
          width: 100%;
          max-width: 100%;
          flex: 1;
        }
      }
    }

    header {
      .header {
        justify-content: space-between;

        @include desktop {
          height: 5.3125rem;
        }

        @include custom-small-desktop {
          height: 4.0625rem;

          cx-page-slot.SiteLogo {
            margin: 0;
          }
        }

        @include mobile {
          min-height: 3.5rem;
          max-height: 3.5rem;
          align-items: center;
          position: fixed;
          top: 0;
          left: 0;
          background: var(--cx-color-dark);
        }

        cx-page-slot {
          &.MiniCart {
            display: flex;
            flex-wrap: nowrap;

            @include mobile {
              row-gap: 0;
              column-gap: 0.25rem;
              margin-right: 0.5rem;
            }

            @include desktop {
              width: 21.875rem;
            }

            @include custom-small-desktop {
              margin: 0;
            }

            app-custom-credit-line {
              display: none;
            }

            app-custom-points-header {
              display: none;
            }

            @include mobile-md {
              margin-right: 0;
            }
          }

          app-custom-mini-cart {
            @include mobile {
              margin-right: 0;
            }

            cx-icon.cx-icon.fas.fa-shopping-cart {
              font-size: 1.125rem;
            }

            .custom-minicart {
              &__count {
                .count {
                  opacity: 0.5;
                }
              }
            }
          }

          &.SiteLogo {
            app-custom-header-logo {
              .isPromeclub img {
                height: 3.6619rem;

                @include custom-small-desktop {
                  height: 3.0625rem;
                }

                @include mobile {
                  height: 2.7525rem;
                }
              }
            }
          }
        }
      }
    }

    cx-page-slot {
      &.TopHeaderSlot {
        display: none;
      }

      &.BottomHeaderSlot.category-page {
        @include mobile {
          position: inherit;
          justify-content: left;
          height: auto;
          width: 100%;
        }

        app-custom-breadcrumb {
          width: 100%;

          .custom-breadcrumb {
            margin-top: 2.5rem;
            padding-bottom: 2rem;

            @include custom-small-desktop {
              margin-top: 1.25rem;
              padding-bottom: 1.5rem;
            }

            @include mobile {
              padding: 0 1.1875rem !important;
              margin-top: 1rem;
              margin-bottom: 1rem;

              > div {
                height: 1rem;
              }
            }
          }

          .custom-breadcrumb.positioning a {
            line-height: 1.1875rem;
          }

          #domTitle {
            justify-content: left;
            padding-bottom: 1.5rem;

            @include custom-small-desktop {
              padding-bottom: 0.875rem;
            }

            @include mobile {
              padding: 0 1.1875rem;
            }

            .custom-breadcrumb__title {
              display: flex;
              align-items: center;
              margin-bottom: 0;

              @include custom-small-desktop {
                font-weight: 900;
                letter-spacing: -0.0088rem;
              }

              @include mobile {
                font-size: 1.375rem;
                text-align: left;
                height: 1.8125rem;
              }
            }
          }
        }
      }

      &.BottomHeaderSlot.checkout-page {
        app-custom-breadcrumb {
          @include mobile {
            height: 0;
          }
        }
      }

      &.BodyContent {
        flex: 66%;
        position: relative;
        height: fit-content;

        @include custom-small-desktop {
          max-width: 100%;
        }

        @include mobile {
          margin-top: 2rem;
        }

        app-custom-checkout-progress {
          margin-bottom: 1.5625rem;

          @include custom-small-desktop {
            margin-bottom: 1rem;
          }

          @include mobile {
            margin-bottom: 0;
            display: none;
          }
        }

        app-custom-checkout-delivery-method {
          .custom-checkout-delivery {
            border: 0.0625rem solid var(--cx-color-grey-dark);
            padding: 0 3rem 0 3rem;
            width: 100%;
            margin-bottom: 3.8125rem;

            @include custom-small-desktop {
              padding: 0 2rem 0 2rem;
              margin-bottom: 2.5rem;
            }

            @include mobile {
              padding: 1.5rem 1rem;
              position: initial;
              min-height: 10rem;
              max-height: fit-content;
              margin-bottom: 2rem;
              background: white;
            }
          }
        }

        app-custom-review-submit {
          @include mobile {
            margin-top: 0;
            padding: 0;
          }
        }
      }

      &.BottomContent {
        app-custom-paragraph {
          display: none;
        }
      }
    }

    @include mobile {
      background-color: var(--promesa-color-neutrals-02);
    }
  }
}
