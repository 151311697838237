@import "src/styles/custom-scss/mixins/media-queries.scss";
@include mobile {
  header {
    cx-page-slot {
      &.TopHeaderSlot {
        height: 2.5rem;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
        &.hide {
          opacity: 0;
          height: 0;
        }
        cx-link {
          display: none;
        }
      }
    }
    .header {
      min-height: 7rem;
      padding: 0.5rem 1rem 0.5rem 1rem;
    }
    cx-page-layout {
      &.header {
        &.fixed {
          position: fixed;
          top: 0;
          left: 0;
          background: var(--cx-color-dark);
        }
        &.height {
          height: 3.5rem;
          min-height: 3.5rem;
          transition: height 0.3s ease-in-out;
        }
        cx-page-slot {
          &.SearchBox {
            opacity: 1;
            transition: opacity 0.1s ease-in-out;
            order: 5;

            &.searchbox-order-sup {
              order: 0;
              width: 50.5%;
              margin: 0;
            }

            &.searchbox-order-inf {
              order: 5;
            }
          }
          &.MiniCart {
            width: 2rem;
            height: 2rem;
            display: grid;
            align-items: start;
            margin: 0 0 0 0.25rem;
            grid-template-columns: auto;
            gap: unset;
            order: 3;
            @include mobile-md {
              gap: unset;
              justify-content: unset;
              margin: 0 0 0 0.25rem;
            }
            app-custom-mini-cart {
              column-gap: 0;
              display: unset;
              position: relative;
              .mini-cart-button {
                width: unset;
                height: unset;
                align-self: unset;
                margin-top: 0.125rem;
              }
              cx-icon.cx-icon.fas.fa-shopping-cart {
                margin-right: 0;
              }
              .custom-minicart {
                position: absolute;
                width: 1.125rem;
                height: 1.125rem;
                left: 0.95rem;
                top: 0.65rem;

                &__count {
                  .count {
                    width: 1.125rem;
                    height: 1.125rem;
                    opacity: 1;
                    &__number {
                      font-size: 10px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 10px;
                      letter-spacing: unset;
                    }
                  }
                }
              }
            }
            .secure-checkout {
              margin-top: 0.225rem;
            }
          }
          &.SiteLogin {
            width: 2rem;
            height: 2rem;
            display: grid;
            align-items: start;
            margin-right: 0.25rem;
            order: 1;
            app-custom-login {
              .login-container {
                display: unset;
                position: unset;
                height: unset;
              }
            }
          }
          &.SiteLogo {
            width: 6.5rem;
            max-height: 2rem;
            order: 0;

            &.hide {
              display: none;
            }

            app-custom-header-logo {
              max-height: 2rem;
              display: grid;
              place-content: center;
              h1 {
                font-size: unset;
              }
            }
            img {
              max-width: 6.5rem;
              height: 100%;
              margin-left: 0;
              @media screen and (max-width: 23.125rem) {
                max-width: 6.5rem;
              }
            }
            &.promeclub {
              width: 3.75rem;
              height: 2.5rem;
              app-custom-header-logo {
                img {
                  max-width: 3.75rem;
                  max-height: 2.5rem;
                }
              }
            }
          }
          &.PreHeader {
            width: 2rem;
            height: 2rem;
            display: grid;
            align-items: start;
            margin-left: 0.25rem;
            order: 4;
          }
        }
        .CreditOrPointsSlot {
          width: 2rem;
          height: 2rem;
          display: grid;
          align-items: start;
          margin: 0 0.25rem 0 0;
          order: 2;
          app-custom-credit-line {
            .credit-line-button {
              position: unset;
              height: unset;
              display: unset;
              font-size: 1.125rem;
              .fas.fa-coins {
                height: unset;
                width: unset;
                display: unset;
              }
            }
          }
        }
      }
    }
  }

  body.searchbox-is-active.has-searchbox-results.searchbox-active-scroll {
    .header {
      cx-page-slot {
        &.SearchBox {
          width: 100%;
          cx-searchbox {
            .results {
              min-height: calc(100vh - 56px);
            }
          }
        }
        &.SiteLogo,
        &.SiteLogin,
        &.CreditOrPointsSlot,
        &.MiniCart,
        &.PreHeader {
          display: none;
        }
      }
    }
  }

  body.searchbox-is-active,
  body {
    header {
      .header {
        cx-page-slot.SearchBox {
          cx-searchbox {
            height: 2.5rem;
            &.dirty div.search-icon,
            div.search-icon {
              padding: 0;
              flex-basis: 0;
            }
            label {
              input {
                height: 2.5rem;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0;
                &::placeholder {
                  font-size: 0.875rem;
                  padding-inline-start: 0;
                  font-weight: 400;
                  line-height: 1rem;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              button.search {
                flex-basis: 2rem;
                width: 24px;
                height: 24px;
                margin: 0.5rem 0.25rem 0.5rem;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
  header {
    &.is-expanded {
      cx-page-layout {
        &.navigation {
          z-index: 4;
          height: 108dvh;
          .CustomSlot,
          .CustomSlot1 {
            background: var(--promesa-color-brand-03);
          }
        }
      }
      cx-page-slot {
        &.PreHeader {
          z-index: 3 !important;
        }
        &.CustomSlot2 {
          background-color: var(--cx-color-dark);
        }
      }
    }
  }
  cx-storefront.MultiStepCheckoutSummaryPageTemplate
    header
    .header
    cx-page-slot.MiniCart {
    width: unset;
  }

  header .navigation {
    .SiteLogin {
      display: none;
    }
    app-custom-category-navigation {
      max-width: 100%;
    }
  }
  app-custom-category-navigation-ui.flyout nav span,
  app-custom-category-navigation-ui.flyout nav h2 {
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1.125rem !important;
  }
  app-custom-category-navigation-ui.flyout nav span {
    border: none;
    margin-right: auto;
  }
  app-custom-category-navigation-ui.flyout nav cx-icon {
    color: var(--promesa-color-neutrals-01) !important;
    &::before {
      font-size: 1rem !important;
    }
  }
  app-custom-category-navigation-ui.flyout nav h2 {
    padding: 0.5rem 1rem !important;
    max-height: 100%;
    span {
      padding: 0;
    }
    .img-icon {
      width: 2rem;
      height: 2rem;
      display: grid;
      place-content: center;
      margin-right: 4px;
      img {
        max-width: 2rem;
        max-height: 2rem;
      }
    }
  }
  app-custom-category-navigation-ui.flyout h2,
  app-custom-category-navigation-ui.flyout cx-generic-link {
    border-bottom: none !important;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  app-custom-category-navigation-ui.flyout {
    .category-ui__title {
      font-size: 1rem;
      line-height: 20px;
      font-weight: 500;
      color: var(--promesa-color-neutrals-01);
      padding: 0 1rem;
      height: 52px;
      margin: 0;
      display: flex;
      align-items: center;
    }
    .separator {
      height: 0.0625rem;
      background: var(--promesa-color-neutrals-03);
      width: calc(100% - 2rem);
      margin: 1.25rem auto;
    }
    nav {
      height: 3rem;
    }
    .wrapper {
      background: var(--promesa-color-brand-03) !important;
      margin-top: 0.25rem;

      .childs {
        nav {
          cx-generic-link {
            a {
              font-size: 0.75rem;
              padding: 0.5rem 1rem;
              color: var(--promesa-color-neutrals-03);
            }
          }
        }
      }
    }
  }
  app-custom-category-navigation-ui.flyout nav.is-open cx-icon,
  app-custom-category-navigation-ui.flyout nav.is-opened cx-icon {
    transform: none !important;
  }
  cx-storefront .mouse-focus .ng-select-focused,
  cx-storefront .mouse-focus :focus {
    outline-style: none !important;
    outline-width: unset !important;
    outline-color: unset !important;
    outline-offset: unset !important;
  }
  app-custom-points-header .pos-icon-mobile {
    top: 0.0625rem !important;
  }
}
