@import "src/styles/custom-scss/mixins/media-queries.scss";

cx-storefront {
  &.ContentPage1Template {
    cx-page-slot.BottomHeaderSlot {
      &.promeclub-form {
        position: absolute;
        left: 0rem;
        top: 12.875rem;

        @include custom-small-desktop {
          left: 0;
          top: 8.125rem;
        }

        @include mobile {
          top: 10.375rem;
        }

        app-custom-breadcrumb {
          .custom-breadcrumb {
            div {
              nav {
                span {
                  .custom-breadcrumb__last-child {
                    color: var(--promesa-color-neutrals-01);
                  }
                }
              }
            }

            @include mobile {
              margin-top: 1rem;
            }
          }
        }
      }

      @include mobile {
        justify-content: left;
      }

      app-custom-breadcrumb {
        z-index: 1;
        .custom-breadcrumb {
          @include mobile {
            width: 100%;
            margin-top: 1.25rem;
          }
        }
        .custom-breadcrumb div nav {
          span,
          a {
            @include mobile {
              font-size: 0.875rem;
            }
          }
        }
      }
    }

    cx-page-layout {
      &.ContentPage1Template {
        padding: 0 4.5rem 1.25rem;

        @include mobile {
          padding: 0 1.1875rem 0;
        }

        @include custom-small-desktop {
          padding: 0 2rem 0;
        }

        cx-page-slot {
          &.Section1 {
            app-custom-static-block {
              &:first-child {
                margin-top: 2.8125rem;

                @include custom-small-desktop {
                  margin-top: 3rem;
                }

                @include mobile {
                  margin-top: 2.5rem;
                }
              }
            }

            app-custom-static-responsive-banner {
              position: relative;
              margin-top: 1.25rem;
              margin-bottom: 3rem;

              @include mobile {
                position: inherit;
                width: 100%;
                margin-top: 1.5rem;

                a {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                }

                cx-media.work-with-us img {
                  height: 10rem;
                }
              }
            }

            app-custom-faq-item {
              p.answer {
                p {
                  margin: 0;
                }
              }

              &:first-child {
                @include mobile {
                  p.title {
                    margin-top: 2.5rem;
                  }
                }
              }

              &:nth-child(4) {
                p.question {
                  margin-bottom: 2.8125rem;

                  @include mobile {
                    margin-bottom: 2.25rem;
                  }
                }
              }

              &:first-child {
                @include custom-small-desktop {
                  margin-top: 3rem;
                }
              }

              &:last-child {
                margin-bottom: 6.5625rem;

                @include mobile {
                  margin-bottom: 2.5rem;
                }

                @include custom-small-desktop {
                  margin-bottom: 4.5rem;
                }

                p.question {
                  margin-bottom: 0;
                }

                p.answer {
                  margin-bottom: 0;
                }
              }
            }

            &.promeclub-form {
              width: calc(100% + 9rem);
              position: relative;
              left: -4.5rem;
              top: 0;

              @include custom-small-desktop {
                width: calc(100% + 4rem);
                left: -2rem;
              }

              @include mobile {
                width: calc(100% + 2.375rem);
                left: -1.1875rem;
              }
            }
            app-custom-simple-responsive-banner {
              margin: 1rem 0;
            }
            app-custom-simple-responsive-banner.landing-promeclub {
              order: 0;
              margin: 0;
              .headline {
                margin: 12.4375rem 4.5rem 3rem 4.5rem;
                text-align: left;
                font-size: 4.0625rem;
                letter-spacing: -0.0253rem;
                color: var(--promesa-color-neutrals-01);
                left: 0;
                width: fit-content;
                text-shadow: none;
                padding: 0;

                @include custom-small-desktop {
                  margin: 8.9375rem 2rem 3rem;
                  text-align: left;
                  font-size: 3rem;
                  line-height: 4rem;
                }

                @include mobile {
                  margin: 7.0625rem 1.1875rem 0;
                  font-size: 1.5rem;
                  letter-spacing: -0.0069rem;
                }

                h4 {
                  font-size: inherit;
                  padding: 0;
                  margin: 0;
                  font-weight: 400;
                  line-height: 1.162;

                  @include custom-small-desktop {
                    letter-spacing: -0.0169rem;
                    line-height: 4rem;
                  }

                  @include mobile {
                    letter-spacing: -0.0093rem;
                    line-height: 1.875rem;
                  }
                }
              }

              .content {
                font-size: 1.5rem;
                line-height: 2rem;
                font-weight: 500;
                letter-spacing: -0.0075rem;
                color: var(--promesa-color-neutrals-01);
                top: 16rem;
                left: 4.5rem;
                text-align: left;
                padding: 0;
                width: fit-content;

                @include custom-small-desktop {
                  top: 10.5rem;
                  left: 2rem;
                }

                @include mobile {
                  padding: 0;
                  font-size: 0.875rem;
                  letter-spacing: -0.0044rem;
                  line-height: 1rem;
                  top: 9rem;
                  left: 1.1875rem;
                  text-align: left;
                  max-width: 16.3125rem;
                  font-weight: 400;
                }

                p {
                  margin: 0;
                }
              }

              cx-media.promeclub-landing img {
                height: 100%;
                width: 100%;

                @include custom-small-desktop {
                  height: 100%;
                  max-width: initial;
                  min-height: calc(100vh - 25.6875rem);
                }

                @include mobile {
                  height: 28.875rem;
                  min-height: 28.875rem;
                  max-width: none;
                }

                @media (min-width: 49.9375rem) and (max-width: $md) {
                  min-height: 44.625rem;
                  height: 44.625rem;
                }

                @media (max-width: $md) and (min-height: 52.6875rem) {
                  height: auto;
                  min-height: calc(100vh - 25.25rem);
                }
              }
            }

            app-custom-promeclub-register-form {
              order: 1;
              position: absolute;
              left: 0;
              top: 25.875rem;
              z-index: 1;
              padding-left: 4.5rem;

              @include custom-small-desktop {
                top: 20.9375rem;
                padding-left: 2rem;
              }

              @include mobile {
                top: 13.3125rem;
                margin-left: 1.1875rem;
                padding: 0;
              }
            }
            app-custom-paragraph {
              div.container-fluid {
                &.videos-tutoriales {
                  h3 {
                    @include custom-small-desktop {
                      font-size: 1.125rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
