@import "src/styles/custom-scss/mixins/media-queries.scss";
cx-page-layout.LandingPage2Template {
  max-width: var(--cx-page-max-width);
  cx-page-slot {
    width: var(--cx-page-width-max);
    max-width: var(--cx-page-width-max) !important;
    padding: 0 !important;
    margin: 0 !important;
    > * {
      border: none;
      color: transparent;
    }
    &.Section1 {
      app-custom-banner-carousel:first-of-type {
        > div {
          margin-bottom: 0;
        }
      }
    }
    &.Section4 {
      padding: 1.5rem 1rem!important;
    }

    &.Section2A,
    &.Section2B,
    &.Section2C {
      flex-basis: 100%;
      @include mobile {
        flex-basis: 100%;
      }
    }
  }
  @include mobile {
    app-custom-product-carousel {
      margin: 0;
      padding: 0;
    }
  }
}
cx-storefront {
  &.LandingPage2Template {
    cx-page-slot.BottomHeaderSlot {
      app-custom-breadcrumb {
        @include desktop {
          min-height: unset;
          &.with-title {
            min-height: unset;
            .custom-breadcrumb {
              min-height: unset;
            }
            #domTitle {
              min-height: unset;
            }
          }
        }
      }
    }

  }
}
