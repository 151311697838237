@import "src/styles/custom-scss/mixins/media-queries.scss";

cx-page-slot.BottomHeaderSlot {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  app-custom-breadcrumb {
    @include desktop {
      min-height: 2.4375rem;

      &.register {
        min-height: unset;
      }

      &.with-title {
        min-height: 7.4375rem;

        .custom-breadcrumb {
          min-height: 3.1875rem;
        }

        #domTitle {
          min-height: 3rem;
        }
      }
    }
  }

  app-custom-search-refiner {
    max-width: var(--cx-page-max-width);
    width: 100%;
    margin: 0 auto;
  }

  @include mobile {
    justify-content: center;
    width: 100%;
    margin: 0;

    &.category-page {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;

      app-custom-search-refiner {
        top: 0;
        width: 100%;
        height: 100%;
      }

      app-custom-breadcrumb {
        top: 0;
        width: 100%;
        position: inherit;
        height: 3rem;

        @include custom-small-desktop {
          height: 2.3125rem;
        }

        @include mobile {
          height: 3.25rem;
        }

        #domTitle {
          justify-content: center;

          @include mobile {
            height: 3.25rem;
            align-items: center;
          }
        }

        &.empty-search {
          @include mobile {
            top: 0;
          }
        }
      }
    }
  }

  app-custom-pdp-rebate {
    width: 100%;
  }
}
