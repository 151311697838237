@import "src/styles/custom-scss/mixins/media-queries.scss";

cx-page-layout.CategoryPageTemplate {
  cx-page-slot {
    &.Section1 {
      margin-top: 0;

      @include mobile {
        display: none;
      }
    }
  }

  app-custom-simple-responsive-banner {
    margin-bottom: 2rem;
    margin-top: 1.25rem;

    @include custom-small-desktop {
      margin-bottom: 1.5rem;
    }

    @include mobile {
      margin-bottom: 1rem;
      margin-top: 4rem;
    }

    p.headline {
      font-family: var(--promesa-font-primary);
      font-size: 2.25rem;
      line-height: 2.625rem;
      color: var(--cx-color-secondary);
      padding: 6.6875rem 6.25rem 6.25rem 12.6875rem;
      text-align: left;
      z-index: 2;

      @include custom-small-desktop {
        padding: 4.25rem 4.25rem 4.25rem 5.25rem;
        font-size: 1.5rem;
        line-height: 2rem;
        text-shadow: none;
      }

      @include mobile {
        font-size: 1.375rem;
        line-height: 1.375rem;
        padding: 2.375rem 1rem 1rem 3rem;
        max-width: 58%;
      }
    }

    cx-generic-link {
      a {
        cx-media {
          max-width: calc(100% - 9rem);
          margin: 0 auto;

          @include custom-small-desktop {
            max-width: calc(100% - 4rem);
          }

          @include mobile {
            max-width: calc(100% - 2rem);
          }
        }
      }
    }
  }

  app-custom-product-carousel {
    @include mobile {
      margin-top: 0;
      margin-bottom: 0.75rem;
    }
  }

  app-custom-category-list {
    margin: 2.125rem 0;

    @include custom-small-desktop {
      margin: 1.5rem 0;
    }

    @include mobile {
      margin: 1rem 0 0.5rem;
    }
  }
}
