/* You can add global styles to this file, and also import other style files */

$styleVersion: 4.3;

@import "~@spartacus/styles/index";
@import "~swiper/swiper-bundle";
@import "/src/styles/custom-scss/buttons";
@import "/src/styles/custom-scss/components/index";
@import "/src/styles/custom-scss/layout/index";
@import "/src/styles/custom-scss/forms";
@import "/src/styles/custom-scss/variables";

:root {
  --cx-color-dark: #{$brand3};
  --cx-color-secondary: #{$brand4};
  --cx-color-primary: #{$brand1};
  --cx-color-white: #{$neutrals1};
  --cx-page-max-width: 120rem;
  --cx-page-width-max: 100%;
  --cx-color-danger: #{$brand5};
  --cx-color-danger-light: #{$system1};
  --cx-color-success: #{$system2};
  --cx-color-grey-light: #{$neutrals2};
  --cx-color-grey-dark: #{$neutrals3};
  --promesa-color-brand-01: #{$brand1};
  --promesa-color-brand-02: #{$brand2};
  --promesa-color-brand-03: #{$brand3};
  --promesa-color-brand-04: #{$brand4};
  --promesa-color-brand-05: #{$brand5};
  --promesa-color-brand-06: #{$brand6};
  --promesa-color-brand-rgba: #{$brandrgba};
  --promesa-color-neutrals-01: #{$neutrals1};
  --promesa-color-neutrals-02: #{$neutrals2};
  --promesa-color-neutrals-03: #{$neutrals3};
  --promesa-color-neutrals-04: #{$neutrals4};
  --promesa-color-neutrals-05: #{$neutrals5};
  --promesa-color-system-01: #{$system1};
  --promesa-color-system-02: #{$system2};
  --promesa-font-primary: "Roboto";
}

html,
body {
  font-family: var(--promesa-font-primary);

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline-style: none;
    }
  }

  .menu-active {
    @media (max-width: 61.9988rem) {
      overflow: hidden;
    }
  }

  &.modal-open {
    @include mobile {
      padding-right: 0 !important;
    }
  }

  .modal-active {
    overflow: hidden;

    @include mobile {
      margin-right: 0 !important;
    }
  }

  cx-promotions .cx-promotions {
    @include custom-small-desktop {
      padding: 0;
    }
  }

  .lock-scroll {
    overflow: hidden;
  }

  .add-review .modal-content {
    @include mobile {
      max-width: 100% !important;
    }
  }

  .modal-content {
    width: fit-content;
    margin: auto !important;
    min-width: 21.875rem;
    border: none;
    border-radius: 0;

    .close {
      margin-top: 0;
    }

    @include mobile {
      min-width: 15.625rem;
      margin: 7.5rem auto auto !important;
      width: calc(100% - 1rem) !important;
      max-width: 25.75rem !important;
    }

    @media screen and (min-width: 26.25rem) and (max-width: 36rem) {
      width: 25.75rem;
    }

    app-custom-add-review-dialog {
      max-width: 41.5rem;

      .close {
        margin: 0;
      }

      @include custom-small-desktop {
        max-width: 36rem;
      }

      @include mobile {
        max-width: 100% !important;
      }
    }
  }
}

html {
  scroll-behavior: smooth;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  ng-select:focus,
  textarea:focus,
  input:focus {
    font-size: 1rem;
  }
}

.modal-without-space {
  margin: 0 !important;
  padding: 0 !important;
}

re-captcha,
re-captcha iframe #rc-anchor-container {
  transform: scale(1);
  transform-origin: 0 0;
}

@include mobile {
  .modal.add-to-cart.fade,
  .modal.add-to-cart.show {
    @include transition($modal-transition);
    transform: translate(0, 100%);
  }

  .modal.add-to-cart.show {
    transform: translate(0, 0);
  }
}
