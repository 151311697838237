@import "/src/styles/custom-scss/mixins/media-queries.scss";

cx-page-layout {
  &.ProductDetailsPageTemplate {
    custom-add-to-cart app-custom-login-form {
      @include mobile {
        height: fit-content;

        .no-display {
          display: none;
        }
      }

      &.user-form {
        @media screen and (min-width: 48rem) {
          max-width: 100%;
          width: 100%;
        }

        @media screen and (min-width: 118.75rem) {
          max-width: 26.9375rem;
          width: 26.9375rem;
        }
      }

      .login-form__body {
        display: block;
        max-width: 26.875rem;
        background: var(--cx-color-grey-light);
        border: 0.0625rem solid var(--cx-color-grey-dark);
        margin-bottom: 1rem;
        width: 100%;

        @media screen and (min-width: 118.75rem) {
          max-width: 26.9375rem;
          width: 26.9375rem;
          height: 25.125rem;
          margin-bottom: 0;

          &.invalid {
            height: unset;
          }
        }

        .label-content {
          line-height: 1.3125rem;
          margin-bottom: 0.25rem;
        }

        @include custom-small-desktop {
          max-width: 21.875rem;
          margin-bottom: 0;
        }

        @include mobile {
          margin-top: 0.5rem;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 0.875rem;
          max-height: 2.5rem;
          background: none;
          border: none;
        }

        .login-form-title {
          font-size: 1.125rem;
          line-height: 1.8125rem;
          letter-spacing: -0.0056rem;
          color: var(--cx-color-secondary);
          border-bottom: 0.0625rem solid var(--cx-color-grey-dark);
          margin-top: 2rem;
          margin-bottom: 0;

          @include custom-small-desktop {
            font-size: 1rem;
            line-height: 1.5rem;
            letter-spacing: -0.005rem;
            margin-top: 0;
          }

          @media screen and (min-width: 118.75rem) {
            max-width: 26.9375rem;
            width: 26.9375rem;
            height: 6.875rem;
            margin-top: 0;
            display: grid;
            place-items: center;
          }

          @include mobile {
            margin-top: 0;
            border: none;
            letter-spacing: -0.005rem;
            color: var(--cx-color-secondary);
            display: flex;
            text-align: center;
            align-items: center;
            height: 2.5rem;
            font-size: 0.75rem;
            line-height: 0.875rem;
            font-weight: 400;
            padding: 0.75rem 4rem 0;
          }

          span {
            display: block;
            padding: 0 2.375rem;
            margin-bottom: 1.5rem;

            @include custom-small-desktop {
              padding: 1.125rem 1.5rem;
              margin: 0;
            }

            @media screen and (min-width: 118.75rem) {
              margin-bottom: 0;
            }

            @include mobile {
              padding: 0;
              margin-bottom: 0;
              width: 100%;
            }
          }
        }

        label {
          padding: 0 2.375rem;
          display: block;
          margin-top: 1.875rem;

          @include custom-small-desktop {
            padding: 0 1.5rem;
            margin-top: 1.5rem;
          }

          @media screen and (min-width: 118.75rem) {
            width: 21.875rem;

            span {
              font-size: 1.125rem;
              line-height: 1.8125rem;
              letter-spacing: -0.0056rem;
            }
          }

          input {
            height: 3.25rem;
            max-height: 3.25rem;

            @include custom-small-desktop {
              height: 3rem;
              max-height: 3rem;
            }
          }

          &.no-display {
            display: none;
          }
        }

        a {
          &.btn-link {
            &.forgot {
              text-decoration: underline;
              font-size: 0.875rem;
              line-height: 1.8125rem;
              letter-spacing: -0.0044rem;
              color: var(--promesa-color-neutrals-04);
              text-align: center;
              width: 100%;
              margin-bottom: 2rem;

              @include custom-small-desktop {
                line-height: 1.1875rem;
                margin-top: 1.125rem;
              }
            }
          }
        }
      }

      button {
        &.btn {
          font-size: 1.25rem;
          line-height: 1.625rem;
          font-weight: 500;
          letter-spacing: -0.0063rem;
          color: var(--cx-color-white);
          height: 4.3125rem;
          max-height: 4.3125rem;
          max-width: 26.875rem;

          @include custom-small-desktop {
            font-size: 1rem;
            line-height: 1.3125rem;
            max-width: 21.25rem;
            height: 3.5rem;
            max-height: 3.5rem;
          }

          @media screen and (min-width: 118.75rem) {
            max-width: 26.9375rem;
            width: 26.9375rem;
            margin-top: 1rem;
          }

          cx-icon {
            position: relative;
            left: 6rem;

            @media screen and (min-width: 118.75rem) {
              left: 9rem;
            }

            @include custom-small-desktop {
              left: 4.5rem;
            }

            @include mobile {
              left: 4.5rem;
            }
          }

          @include mobile {
            margin-top: 0;
            height: 2.625rem;
            max-height: 2.625rem;
            max-width: 20.5rem;
            padding: 0.75rem 1rem;
            font-size: 0.875rem;
          }
        }
      }

      a.btn-link {
        @include mobile {
          display: flex !important;
          justify-content: center;
          gap: 0.25rem;
          background-color: var(--cx-color-grey-light);
          margin-top: 1rem !important;
          padding: 0.375rem 0;
        }
        &.text-intermediate {
          @include mobile {
            height: 1.875rem;
            margin-bottom: 1rem !important;
            font-size: 0.75rem;
            line-height: 0.875rem;
            width: 20.5rem;
          }
          .btn-link-register {
            flex: initial;
            width: fit-content;
            margin: 0 !important;
            font-size: 0.75rem;
            line-height: 0.875rem;
            text-decoration: none;
            color: var(--cx-color-primary);
          }
        }
        &.no-underline {
          text-align: center;
          text-decoration: none;
          font-size: 0.875rem;
          line-height: 1.8125rem;
          letter-spacing: -0.0044rem;
          color: var(--promesa-color-neutrals-04);
          width: 100%;
          flex: 100%;
          margin-top: 0;
          max-width: 26.9375rem;
          padding-top: 0.75rem;

          @media screen and (min-width: 118.75rem) {
            height: 2.6875rem;
          }

          @include custom-small-desktop {
            max-width: 21.875rem;
            line-height: 1.1875rem;
            height: 1.1875rem;
          }

          @include mobile {
            height: 2.625rem;
            padding: 0.407rem 0;
            background: var(--cx-color-grey-light);
            font-size: 0.75rem;
            line-height: 1.625rem;
            letter-spacing: -0.0037rem;
          }

          span {
            &.btn-link {
              font-size: 0.875rem;
              line-height: 1.8125rem;
              letter-spacing: -0.0044rem;
              color: var(--promesa-color-neutrals-04);

              @include custom-small-desktop {
                line-height: 1.1875rem;
              }
            }
          }
        }
        &.no-display {
          display: none !important;
        }
      }
    }
  }
}
