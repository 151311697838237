@import "src/styles/custom-scss/mixins/media-queries.scss";
@import "/src/styles/custom-scss/placeholders/header-small-desktop.scss";

.header {
  cx-page-slot.SiteLogin {
    max-width: 8.75rem;
    @extend %margin-top-header-slots-small-dekstop;

    @include desktop {
      padding: 0;
      height: 3.125rem;
      display: flex;
      justify-content: center;
      align-items: center;
      app-custom-login {
        display: flex;
        justify-content: flex-end;
      }
    }

    @include custom-small-desktop {
      height: 2.5rem;
    }

    @include large-desktop {
      height: 2.5rem;
      margin-top: 0.75rem;
    }

    @include mobile-md {
      margin-left: auto;
      margin-right: 0;
      width: initial;
    }

    @include mobile {
      margin-left: auto;
      background-color: transparent;
      app-custom-login {
        span {
          display: none;
        }
        a {
          span {
            display: none;
          }
        }
        .login-container {
          &.loggedin {
            .user-name {
              display: none;
            }
            .HeaderLinks {
              display: none;
            }
          }
        }
      }
    }
  }
}
header .navigation {
  @include mobile {
    .SiteLogin {
      background-color: var(--cx-color-dark);
      border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
      app-custom-login {
        .logged-off {
          color: var(--cx-color-white);
        }
      }
    }
    .SiteContext,
    .SiteLinks {
      background-color: var(--cx-color-dark);
    }
    .SiteContext {
      padding: 6.5rem 1rem 0.25rem;
    }
  }
}
