cx-storefront {
  &.ProductGridPageTemplate {
    cx-page-slot {
      &.BottomHeaderSlot.category-page {
        app-custom-breadcrumb {
          .custom-breadcrumb {
            padding-bottom: 2rem;

            @include mobile {
              height: -webkit-fill-available;
              display: flex;
              align-items: center;
            }
          }

          #domTitle {
            h1 {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

cx-page-layout.ProductGridPageTemplate {
  .ProductGridSlot {
    .cx-page {
      @include custom-small-desktop {
        padding-bottom: 2rem;
      }

      .cx-page-section {
        .container {
          max-width: var(--cx-page-width-max);
          padding: 0;
        }
      }
    }
  }
}

cx-page-layout.ProductGridPageTemplate .ProductListSlot {
  max-width: 90%;

  app-custom-product-list {
    max-width: 100%;
  }

  @include mobile {
    max-width: 100%;
  }
}

cx-page-layout.ProductGridPageTemplate .ProductGridSlot {
  max-width: 83%;

  @include custom-small-desktop {
    max-width: calc(100% - 17.375rem);
  }

  app-custom-product-list {
    max-width: 100%;
  }

  @include mobile {
    max-width: 100%;
  }
}
