@import "src/styles/custom-scss/mixins/media-queries.scss";

header {
  @include mobile {
    z-index: 24;
  }
  .header {
    @include desktop {
      padding: 0 4.5rem;
    }

    @include custom-small-desktop {
      padding: 0 2rem;
    }

    @include mobile {
      min-height: 7.5rem;
      padding: 0.25rem 1.1875rem 0.6875rem;
      justify-content: space-between;
      align-content: inherit;
    }

    .SiteContext {
      color: transparent;
    }
  }

  > .TopHeaderSlot {
    display: flex;
    align-items: center;
    height: 2rem;
    background: var(--cx-color-secondary);
    padding: 0 4.5rem 0 4.75rem;
    max-width: 100%;
    width: 100%;

    &.hide {
      display: none;
    }

    @include custom-small-desktop {
      height: 2rem;
      padding: 0 2rem;
    }

    @include mobile {
      padding: 0 1.1875rem;
      height: 2.9375rem;
    }

    @media screen and (max-width: 25.625rem) {
      padding: 0 0.5rem;
    }

    cx-link {
      cx-generic-link {
        @include custom-small-desktop {
          height: 1.25rem;
          display: flex;
          align-items: center;
        }
        @include large-desktop {
          height: 1.25rem;
          display: flex;
          align-items: center;
        }
        a {
          text-align: left;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.25rem;
          letter-spacing: -0.0044rem;
          color: var(--cx-color-white);
          pointer-events: none;
          cursor: default;

          @include custom-small-desktop {
            display: block;
            font-size: 0.75rem;
            line-height: 0.875rem;
            letter-spacing: -0.0037rem;
          }
          @include large-desktop {
            height: 0.875rem;
            font-size: 0.875rem;
            line-height: 0.875rem;
          }

          @include mobile {
            font-size: 0.75rem;
            line-height: 1rem;
            letter-spacing: -0.0037rem;
          }

          @media screen and (max-width: 25.625rem) {
            font-size: 0.625rem;
            line-height: 0.875rem;
          }
        }
      }

      &:first-of-type {
        margin-left: auto;
        margin-right: 2.3125rem;

        @include custom-small-desktop {
          margin-right: 2.5rem;
        }

        @include mobile {
          margin-right: 1.25rem;
        }

        @media screen and (max-width: 25.625rem) {
          margin-right: 0.5rem;
        }
      }
    }
  }

  cx-page-layout.header .CreditOrPointsSlot {
    margin: 0 auto;
    @extend %margin-top-header-slots-small-dekstop;

    @include mobile {
      margin: 0 1.875rem;
    }

    @include custom-small-desktop {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
    @include large-desktop {
      margin: 0.75rem 1.5rem 0;
    }
  }

  cx-page-layout.header cx-page-slot.MiniCart {
    justify-content: space-between;
    display: grid;
    grid-template-columns: auto auto;

    @include mobile-md {
      gap: 1.0625rem;
      justify-content: initial;
      margin-left: 0;
      margin-right: 1.25rem;
    }

    @include mobile {
      gap: 0.5rem;
    }

    @media screen and (max-width: 25.625rem) {
      margin-right: 0;
    }

    @include tablet {
      width: 5rem;
    }

    app-custom-credit-line {
      @include mobile {
        width: 5rem;
        display: flex;
        gap: 1.25rem;
        justify-content: flex-start;
        align-items: flex-end;
        margin: 0;
      }
    }
    @include large-desktop {
      height: 40px;
      margin-top: 0.75rem;
    }
  }
}
