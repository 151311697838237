@import "src/styles/custom-scss/variables.scss";

@mixin ng-select-small-desktop-component {
  .ng-value-container {
    @include custom-small-desktop {
      padding-inline-start: 1rem !important;
    }
  }

  .ng-arrow-wrapper {
    @include custom-small-desktop {
      margin-right: 1.25rem;
      width: 1.5rem;
      padding: 0;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ng-arrow {
      @include custom-small-desktop {
        width: 0.75rem;
        height: 0.75rem;
      }
    }
  }
}

@mixin ng-select-mobile {
  @include mobile {
    ng-select {
      border: 0.0625rem solid $grey-light;
      border-radius: 0.3125rem;
      padding: 0.375rem;
    }

    .ng-select.ng-select-single .ng-select-container .ng-value-container {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .ng-input {
        border: 0;
      }
    }

    .ng-arrow-wrapper {
      padding-right: 1.125rem;
      width: 2.125rem;

      .ng-arrow {
        border-color: var(--cx-color-primary) !important;
        width: 0.625rem;
        height: 0.625rem;
      }
    }
  }
}
