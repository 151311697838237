$extra-xxl: 120rem;
$xxl: 100rem;
$xl: 85.375rem;
$lg: 80rem;
$md: 62rem;
$sm: 36rem;
$xs: 0rem;

$mobile-width: $md;
$notebook-width: $xl;
$medium-width: $lg;
$large-width: $xxl;
$extra-large-width: $extra-xxl;

@mixin mobile-sm {
  @media (max-width: #{$sm - .0625rem}) {
    @content;
  }
}

@mixin mobile-md {
  @media (max-width: 47.9375rem) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile-width - .0625rem}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 48rem) and (max-width: #{$mobile-width - .0625rem}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$mobile-width}) {
    @content;
  }
}

@mixin small-desktop($pixels: 0rem) {
  @media (min-width: calc(#{$mobile-width} + #{$pixels})) {
    @content;
  }
}

// > 1280px
@mixin medium-desktop {
  @media (min-width: #{$medium-width}) {
    @content;
  }
}

// > 1366px
@mixin notebook-desktop {
  @media (min-width: calc(#{$notebook-width} + .0625rem)) {
    @content;
  }
}

// > 1600px
@mixin large-desktop {
  @media (min-width: #{$large-width}) {
    @content;
  }
}

// > 1920px
@mixin extra-large-desktop {
  @media (min-width: calc(#{$extra-large-width} + .0625rem) ) {
    @content;
  }
}

// Custom small desktop 992px to 1599px
@mixin custom-small-desktop($pixels: 0rem) {
  @media (min-width: calc(#{$mobile-width} + #{$pixels})) and (max-width: calc(#{$large-width} - .0625rem)) {
    @content;
  }
}

// 992 px to 1280 px
@mixin custom-desktops {
  @media (min-width: $md) and (max-width: $lg) {
    @content;
  }
}

// 420px to 992px for mobiles large
@mixin custom-mobile($pixels: 0rem) {
  @media (min-width: 26.25rem) and (max-width: calc(#{$mobile-width} - .0625rem)) {
    @content;
  }
}

// 320px to 419px for small mobiles
@mixin custom-small-mobile($pixels: 0rem) {
  @media (min-width: 20rem) and (max-width: 26.1875rem) {
    @content;
  }
}
// 320px to 375px for mobiles extra small
@mixin custom-extra-small-mobile {
  @media (min-width: 20rem) and (max-width: 23.4375rem) {
    @content;
  }
}

@mixin design-mobile {
  @media (max-width: 26.75rem) {
    @content;
  }
}
