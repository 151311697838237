@import "src/styles/custom-scss/mixins/media-queries.scss";

footer,
.footer {
  @include desktop {
    margin-top: 2.5rem;
  }
  @include custom-small-desktop {
    margin-top: 0;
  }
}
