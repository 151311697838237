@import "src/styles/custom-scss/mixins/media-queries.scss";

body .modal.pre-add-to-cart .modal-content,
body .modal.promeclub-add-to-cart .modal-content,
body .modal.add-to-cart .modal-content,
body .modal.add-review .modal-content,
body .modal.review-confirmated .modal-content,
body .modal.future-stock .modal-content  {
  @include mobile {
    margin: auto auto 0 auto !important;
    width: 100% !important;
    border-radius: 1rem 1rem 0 0;
    max-width: 36rem !important;
  }
}
