@import "src/styles/custom-scss/mixins/ng-select-component.scss";

cx-page-layout.SearchResultsListPageTemplate {
  max-width: var(--cx-page-width-max);
  margin: auto;
  display: flex;
  align-content: initial;
  flex-wrap: nowrap;
}

cx-storefront.SearchResultsListPageTemplate {
  @include desktop {
    cx-page-slot {
      &.BottomHeaderSlot {
        app-custom-breadcrumb {
          .custom-breadcrumb {
            padding-bottom: 2rem;
          }

          #domTitle {
            height: 1.5rem;

            h1 {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

cx-page-layout.SearchResultsListPageTemplate,
cx-page-layout.ProductGridPageTemplate {
  padding: 1.25rem 4.5rem 0;

  @include custom-small-desktop {
    padding: 1rem 2rem;
  }

  @include mobile {
    padding: 0 1rem 0.5rem 1rem;
    cx-icon[type="FILTER_MOBILE"] {
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .SearchResultsListSlot {
    max-width: 90%;

    app-custom-product-list {
      max-width: 100%;
    }

    @include mobile {
      max-width: 100%;
    }

    .cx-page {
      .cx-page-section {
        .container {
          max-width: var(--cx-page-width-max);
          padding: 0;
        }
      }
    }
  }

  .ProductLeftRefinements {
    padding-left: 0;
    margin-right: 1rem;
    max-width: 17.625rem;
    padding-inline-end: 0;
    padding-inline-start: 0;
    padding-top: 2.125rem;

    @include mobile {
      max-width: unset;
      padding: 0;
      padding-inline: 0;
    }

    @include custom-small-desktop {
      max-width: 16.375rem;
      padding-top: 1.8125rem;
    }

    > app-custom-product-facet-navigation {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: unset;
      padding: 0;

      @include mobile {
        padding: 0;
      }

      > app-custom-active-facets {
        > div {
          > a {
            > cx-icon {
              > svg {
                width: 0.875rem;
                height: 0.875rem;
              }
            }
          }
        }
      }

      @include mobile {
        justify-content: center;
        flex-direction: column-reverse;
      }

      > .dialog-trigger {
        @include mobile {
          display: flex;
        }

        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        gap: 1.8125rem;
        width: 100vw;
        max-width: 20.875rem;
        height: 3.6875rem;
        max-height: 3.6875rem;
        border: none;
        border-radius: 0;
        color: var(--cx-color-white);
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.5rem;
        letter-spacing: 0.01rem;

        > cx-icon {
          width: 1.5rem;
          height: 1.5rem;
          color: var(--cx-color-white);

          > svg {
            width: 1rem;
            height: 0.625rem;
          }
        }

        &:focus {
          outline: unset;
          box-shadow: none;
        }

        &:active {
          border: none;

          &:focus {
            box-shadow: none;
          }
        }
      }

      > app-custom-facet-list {
        max-width: 17.625rem;

        @include mobile {
          max-width: calc(100% - 2rem);
        }

        @include custom-small-desktop {
          max-width: 16.375rem;
        }

        > .inner {
          padding-left: 0rem;
          color: #000000de;

          @include desktop {
            padding-inline-end: 0;
          }

          @include mobile {
            app-custom-facet,
            app-custom-price-facet,
            app-custom-facet-reviews {
              &::before {
                content: "";
                width: 100%;
                left: 0;
                position: absolute;
                border-top: 0.0625rem solid #989da530;
              }
              app-custom-product-reviews-overview {
                max-width: 100%;
              }
            }
          }

          @include ng-select-mobile;

          ng-select {
            @include mobile {
              height: 3.25rem;
            }
          }

          > .focus-lock {
            padding-top: 0.7rem;
            line-height: 1.25rem;
            border-top: 0.0625rem solid #0000001f;

            > .more {
              margin: 1.038rem 0 1.25rem;
            }
          }
        }

        .facets-filter {
          max-width: 17.625rem;

          @include mobile {
            padding: 0;
            position: relative;
            max-width: 100%;
            overflow: auto;
            height: calc(100% - 10.8125rem);

            &__children {
              padding: 0 1.25rem;
              @include mobile {
                padding: 0 1rem;
              }
            }
          }
        }

        &.active {
          &.dialog {
            background-color: #666666;
            @include mobile {
              background-color: var(--promesa-color-neutrals-01);
            }
            > .inner {
              transform: translate3d(0, 0, 0);

              @include mobile {
                overflow: hidden;
                padding: 0;
              }
            }
          }
        }

        &.dialog {
          justify-content: flex-end;
          align-items: unset;
          z-index: 30;

          > .inner {
            width: 100%;
            height: 100%;
            padding-inline: 0;
            transform: translate3d(-100%, 0, 0);
            box-shadow: 35px 1px 8px 0px rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: 35px 1px 8px 0px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 35px 1px 8px 0px rgba(0, 0, 0, 0.1);
            @include mobile {
              background-color: var(--promesa-color-neutrals-01);
            }
            .title-filter {
              height: 5.625rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background-color: var(--cx-color-dark);
              padding: 0 0.9375rem;
              @include mobile {
                background-color: var(--promesa-color-neutrals-01);
                border-bottom: 0.0625rem solid var(--promesa-color-neutrals-03);
                height: 3.5rem;
                padding: 0 1rem;
              }

              p {
                background-color: var(--cx-color-dark);
                text-align: center;
                color: var(--cx-color-white);
                width: 100%;
                margin: 0;
                @include mobile {
                  text-align: left;
                  color: var(--promesa-color-brand-04);
                  font-size: 1.125rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 1.5rem;
                  background-color: var(--promesa-color-neutrals-01);
                  color: var(--promesa-color-brand-04);
                }
                > .close {
                  visibility: visible;
                  opacity: 1;
                  > cx-icon {
                    color: var(--cx-color-primary);
                    background-color: var(--cx-color-dark);
                    text-shadow: none;
                    padding: 0;
                    margin: 0;
                    @include mobile {
                      background-color: var(--promesa-color-neutrals-01);
                      font-size: 1rem;
                      &:hover {
                        color: var(--cx-color-primary);
                      }
                    }
                    > svg {
                      width: 0.875rem;
                      height: 0.875rem;
                    }
                  }
                }
              }
            }

            .facets-filter__all {
              margin: 0 1.25rem;

              @include mobile {
                overflow-x: scroll;
              }
            }

            > .focus-lock {
              > .value {
                max-width: 100%;
              }
            }

            @include mobile {
              max-height: 100%;
            }
          }
        }
      }
    }
  }
}
