%progress-bar {
  &:before,
  &:after {
    height: 0;
    width: 0;
    left: 0;
    top: 0;
    margin: 0;
    content: '';
    position: unset;
    z-index: 0;
  }
  &:before {
    background-color: transparent;
  }

  &:after {
    background-color: transparent;
  }
}
