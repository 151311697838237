.custom-modal-container,
.cx-dialog-header {
  &.modal-header,
  &__custom-modal-header {
    justify-content: flex-end;
    border-bottom: none;
    margin-bottom: 0;
    padding: 1rem 1rem 0 0;
    .close {
      font-size: 1.5rem;
      color: var(--cx-color-primary);
      padding: 0;
      margin: 0;
      position: unset;
      opacity: 1;
      width: 1.8125rem;
      height: 1.8125rem;
    }

    @include custom-small-desktop {
      margin-bottom: 0;
      height: 1.125rem;
      margin: 1.375rem 1.375rem 0 0;
      padding: 0;
      .close {
        width: 0.75rem;
        height: 0.75rem;
        margin-bottom: auto;

        cx-icon {
          width: 0.75rem;
          height: 0.75rem;
          display: flex;
        }

        > span {
          height: 0.75rem;
          display: flex;
        }

        .fa-times:before {
          color: var(--cx-color-primary);
          font-size: 1.09375rem;
        }
      }
    }
  }
}

.cx-dialog-title.modal-title.loading-title {
  margin: 2rem 0;
}
