@import "src/styles/custom-scss/mixins/media-queries.scss";
cx-page-layout.OrderConfirmationPageTemplate {
  @include desktop {
    padding: 0 4.5rem;
    align-items: flex-start;
  }

  @include custom-small-desktop {
    padding: 0 2rem;
  }

  @include mobile {
    padding: 1.25rem 1rem 0 1rem;
  }
  cx-page-slot.BottomHeaderSlot {
    display: none;
  }
}
